import React, { useEffect } from "react";
import styled from "styled-components";
import { mobileSize } from "../../util/variables";
import theme from "../../util/theme";
import { ThankYou } from "../../components/PageSegments/subscription/ThankYouV2";
import { BasicLayout } from "../../layouts/BasicLayout";
// import { getStripePaymentData } from "../../actions/stripeAction";
// import { navigate } from "gatsby";
import { subscription_success } from "../../services/mixpanel/mixpanel";
// import { setupSpecialOfferSubscription } from "../../services/firebase";
// import Loading from "../../components/ui/loading";
import {
  web_acq_post_payment_fail,
  // web_acq_post_payment_success,
  web_acq_post_payment_view,
} from "../../services/mixpanel/acquisition-events";
import { triggerCustomEvent } from "../../services/google-tag-manager";
import { GTM_CUSTOM_EVENT } from "../../util/types";
import { sendFBConversionEvent } from "../../services/pixel";
import { useAuth } from "../../hooks/useAuth";
// import { sendSlackErrorMessage } from "../../services/slack";

export default (data: any) => {
  const { search } = data.location;
  const urlParams = new URLSearchParams(search);
  const sessionParam = urlParams.get("session_id");
  const uidParam = urlParams.get("uid");
  const { user, loading } = useAuth();

  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    // DEPRECATED. Used for old flow when account creation was done after payment.
    // const initForm = async () => {
    //   try {
    //     const res = await getStripePaymentData(sessionParam ?? "");
    //     const { customer_id, subscription_id, product } = await res;
    //     await setupSpecialOfferSubscription({
    //       customer_id,
    //       fetch_token: subscription_id,
    //       product,
    //       uid: uidParam ?? "",
    //     });
    //     web_acq_post_payment_success();
    //   } catch (err) {
    //     web_acq_post_payment_fail();
    //     console.log(err);
    //     sendSlackErrorMessage(
    //       `Post payment error: [uid: ${uidParam}] [session_id: ${sessionParam}] \n ${err}`,
    //     );
    //     alert(
    //       "There was possibly an error connecting your subscription. If you have trouble with your account, please contact support",
    //     );
    //   } finally {
    //     setLoading(false);
    //   }
    // };
    if (loading) return;
    if (sessionParam) {
      web_acq_post_payment_view();
      if (uidParam || user?.uid) {
        sendFBConversionEvent({
          value: 69.99,
          uid: uidParam ?? user!.uid,
          currency: "USD",
        });
      }

      triggerCustomEvent(GTM_CUSTOM_EVENT.PURCHASE);
      // initForm();
    } else {
      web_acq_post_payment_fail();
      // setLoading(false);
    }

    localStorage.clear();
    sessionStorage.setItem("landing_page", "");
    subscription_success({ subscription_type: "yearly" });
  }, [sessionParam, uidParam, loading, user]);

  return (
    <BasicLayout noIndex>
      <FlexContainer>
        {/* {loading ? (
          <LoadingContainer>
            <Loading />
          </LoadingContainer>
        ) : ( */}
        <ThankYou />
        {/* )} */}
      </FlexContainer>
    </BasicLayout>
  );
};

const FlexContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  background: ${theme.WHITE_COLOR};

  @media ${mobileSize} {
    justify-content: flex-start;
  }
`;
