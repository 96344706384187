import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { BasicLayout } from "../layouts/BasicLayout";
import { BottomNavSegment } from "../components/PageSegments";
import { NavBar, Wrapper } from "../components/ui";
import { mobileSize } from "../util/variables";

export default () => {
  return (
    <BasicLayout>
      <Main>
        <NavBar />
        <Wrapper>
          <Content>
            <Title>Deleting Your Imprint Account</Title>
            <Subtitle>To delete your Imprint account follow these steps:</Subtitle>
            <ol>
              <li>Login to iOS or Android Imprint app.</li>
              <li>Tap on the profile icon in the upper right corner of the home screen.</li>
              <li>Tap the link that says ‘Delete Account’.</li>
              <li>Click ‘Delete’ on the confirmation dialog.</li>
            </ol>
            <p>Your Imprint account should now be deleted.</p>
            <Subtitle>
              What does deleting my account do?
            </Subtitle>
            <p>
              Deleting your Imprint account removes your library, progress, and
              saved cards from Imprint’s servers. Deleting your account does not
              cancel your subscription. To cancel your subscription,{" "}
              <Link to="/faq">
                follow the instructions in our FAQ.
              </Link>
            </p>
          </Content>
        </Wrapper>
      </Main>
      <BottomNavSegment />
    </BasicLayout>
  );
};

const Main = styled.main`
  display: flex;
  margin-bottom: 80px;
  flex-direction: column;
`;

const Content = styled.section`
  max-width: 800px;
  height: 100%;
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  margin-bottom: 40px;
  margin-top: 80px;
  font-size: 42px;
  @media ${mobileSize} {
    margin-top: 0px;
    font-size: 32px;
  }
`;

const Subtitle = styled.h2`
  margin-bottom: 20px;
`;
