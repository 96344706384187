import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { mobileSize } from "../../../../util/variables";
import { TopBanner } from "../../../ui/TopBanner";
import { useLocalPriceContext } from "../../../../providers/LocalPriceProvider";

export const SpecialOfferBar = () => {
  const { getDiscountPercent } = useLocalPriceContext();

  return (
    <TopBanner>
      <BannerText>
        🏆 Winner of Google’s <b>App of the Year!</b> <MobileBr />Celebrate with{" "}
        <Link
          to="/onboarding/questions"
          style={{ fontWeight: 500 }}
        >
          {getDiscountPercent()}% off
        </Link>
      </BannerText>
    </TopBanner>
  );
};

const MobileBr = styled.br`
  display: none;
  @media ${mobileSize} {
    display: block;
  }
`;

const BannerText = styled.p`
  margin: 0;
  line-height: 1.25;
  @media ${mobileSize} {
    font-size: 20px;
  }
`;
