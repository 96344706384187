import React from "react";
import { AppText } from "../../../ui";
import { Link } from "gatsby";
import { OfferGoSegmentI } from "../../../../hooks/useOffer";
import { useAuth } from "../../../../hooks/useAuth";

interface AuthStatusMessageProps {
  priceData: OfferGoSegmentI["priceData"];
  color?: string;
}

const AuthStatusMessage: React.FC<AuthStatusMessageProps> = ({ priceData, color }) => {
  const { user } = useAuth();

  if (!user) {
    return null;
  }

  return (
    <AppText fontSize={14} mobileFontSize={12} color={color} style={{ marginTop: 8, fontStyle: "italic" }}>
      You&apos;re currently signed in as {user.email}. Not you?{" "}
      <Link
        to="/subscription/register?from=email"
        state={{
          couponId: priceData.coupon_id,
          priceId: priceData.price_id,
          trial: priceData.trial,
        }}
      >
        Log out
      </Link>
    </AppText>
  );
};

export default AuthStatusMessage;
