import styled from "styled-components";
import * as React from "react";
import { NavBar, Wrapper, AppTitle } from "../components/ui/";
import { BottomNavSegment } from "../components/PageSegments/BottomNav";
import { Link } from "gatsby";
import { BasicLayout } from "../layouts/BasicLayout";
import theme from "../util/theme";

export default () => {
  return (
    <BasicLayout>
      <Main>
        <NavBar initiallyWhite position="fixed" />
        <Wrapper>
          <WhiteDiv>
            <AppTitle>Page Not Found!</AppTitle>
            <Link to="/" style={{ color: theme.PRIMARY_COLOR }}>Go to home page</Link>
          </WhiteDiv>
        </Wrapper>
        <BottomNavSegment />
      </Main>
    </BasicLayout>
  );
};

const WhiteDiv = styled.div`
  width: 100%;
  background-color: transparent;
  margin-top: 300px;
  margin-bottom: 300px;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
