/* eslint-disable @typescript-eslint/no-unused-vars */
import { Link, navigate } from "gatsby";
import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { updateUserPassword } from "../../../services/firebase";
import { mobileSize } from "../../../util/variables";
import theme from "../../../util/theme";
import { AppButton, AppTitle, StyledField } from "../../ui";
import { confirmPasswordAssertion, passwordAssertion } from "../../../util/yup-schemas";
import { trackEvent } from "../../../services/mixpanel/mixpanel";

const userSchema = Yup.object().shape({
  password: passwordAssertion,
  confirmPassword: confirmPasswordAssertion,
});

const InitialResetForm = ({ setSubmitted }: { setSubmitted: (val: boolean) => void }) => {
  const handleSubmit = async (values: { password: string; confirmPassword: string }) => {
    try {
      const { password } = values;
      const res = await updateUserPassword(password);
      if (res.success) {
        trackEvent("WebEmail_Update_Password_Success");
        setSubmitted(true);
      }
    } catch (err) {
      console.error(err);
      trackEvent("WebEmail_Update_Password_Failure");
      alert(err.message);
    }
  };

  return (
    <Formik
      initialValues={{
        password: "",
        confirmPassword: "",
      }}
      validationSchema={userSchema}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form>
          <BodyContainer>
            <StyledField type="password" name="password" width="100%" />
            <StyledField type="password" name="confirmPassword" width="100%" />

            <ButtonContainer>
              <AppButton type="submit">Update Password</AppButton>
            </ButtonContainer>
          </BodyContainer>
          <RedirectLink to="/user/profile">Back to Account</RedirectLink>
        </Form>
      )}
    </Formik>
  );
};

const PostResetForm = () => {
  const handleSubmit = () => {
    navigate("/user/login");
  };

  return (
    <BodyContainer>
      <BodySection>
        <ResetConfimration>
          Your password has been changed. Be sure to use your new password the next time you log in
          to your Imprint account.{" "}
        </ResetConfimration>
      </BodySection>

      <ButtonContainer>
        <AppButton onClick={handleSubmit}>Return to Account</AppButton>
      </ButtonContainer>
    </BodyContainer>
  );
};

export const UpdatePasswordForm = () => {
  const [submitted, setSubmitted] = useState(false);

  return (
    <Container>
      <ContentContainer>
        <AppTitle>Update password</AppTitle>
        {submitted ? <PostResetForm /> : <InitialResetForm setSubmitted={setSubmitted} />}
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  background-size: cover;
  overflow: hidden;
  position: relative;
  min-height: 800px;
  max-height: fit-content;
  height: 100vh;
  width: 100%;
`;

const ContentContainer = styled.section`
  max-width: 588px;
  margin: auto;
  margin-top: 200px;

  @media ${mobileSize} {
    margin-top: 200px;
    max-width: 327px;
  }
`;

const BodyContainer = styled.form`
  width: 100%;
  margin: auto;
  margin-top: 48px;
  max-width: 588px;
  border-radius: 16px;
`;

const BodySection = styled.div``;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 64px;
`;

const RedirectLink = styled(Link)`
  font-family: ${theme.SECONDARY_FONT};
  display: block;
  margin-top: 16px;
  font-weight: 700;
  font-size: 12px;
  text-align: center;

  text-decoration-line: none;
  color: #8d8794;
`;

const ResetConfimration = styled.p`
  font-family: ${theme.SECONDARY_FONT};
  font-size: 20px;
  line-height: 25px;
  color: #716b78;
  a {
    color: linear-gradient(90deg, rgba(115, 123, 253, 0.75) 1.68%, rgba(50, 123, 210, 0.75) 116.81%),
      #1e85e2;
    text-decoration: none;
  }
`;
