import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { trackEvent } from "../../../services/mixpanel/mixpanel";
import theme from "../../../util/theme";
import { mobileSize } from "../../../util/variables";
import { AnswerProps } from "./Answers";
import { ContinueButton } from "./HappinessQuestions";

export const SingleSelectAnswer: React.FC<AnswerProps> = ({ choices, handleNext }) => {
  const [selected, setSelected] = useState("");

  useEffect(() => setSelected(""), [choices]);

  const handleClick = (choice: string) => {
    trackEvent("Web_Happiness_Onboarding_Questions_SelectAnswer");
    setSelected(choice);
  };

  return (
    <AnswersContainer>
      {choices.map(choice => (
        <SingleSelectContainer
          key={choice}
          selected={choice === selected}
          onClick={() => handleClick(choice)}
        >
          {choice}
        </SingleSelectContainer>
      ))}
      <ContinueButton disabled={!selected} margin="48px 0 0 0" onClick={() => handleNext(selected)}>
        Next
      </ContinueButton>
    </AnswersContainer>
  );
};

const AnswersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

type SingleSelectProp = {
  selected: boolean;
};

const SingleSelectContainer = styled.div<SingleSelectProp>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 588px;
  min-height: 48px;

  padding: 12px 16px;
  border: 2px solid ${props => (props.selected ? theme.SELECTED_BORDER_COLOR : "#d2d5d8")};
  border-radius: 8px;
  box-sizing: border-box;

  font-weight: 600;
  text-align: center;
  background: ${props => (props.selected ? theme.SELECTED_COLOR : theme.WHITE_COLOR)};
  cursor: pointer;
  transition: all 0.2s;
  
  @media screen and (min-width: 800px) {
    line-height: 16px;
    :hover {
      background: ${props => (props.selected ? theme.SELECTED_COLOR : theme.HOVER_COLOR)};
      border-color: ${props => (props.selected ? theme.SELECTED_BORDER_COLOR : "transparent")};
    }
  }

  :active {
    background: ${theme.ACTIVE_COLOR};
  }

  @media ${mobileSize} {
    /* min-height: 32px */
    padding: 8px 16px;
  }
`;
