import { Form, Formik, FormikProps } from "formik";
import { Link, navigate } from "gatsby";
import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import { login } from "../../../services/firebase";
import {
  track_email_login_fail,
  track_email_login_start,
  track_email_login_success,
} from "../../../services/mixpanel/mixpanel";
import theme from "../../../util/theme";
import { mobileSize } from "../../../util/variables";
import { AppLoadingButton, AppTitle } from "../../ui";
import { existingUserSchema } from "../../../util/yup-schemas";
import { EmailPasswordForm, EmailPasswordI } from "./EmailPasswordForm";

export const UserForm = () => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: EmailPasswordI) => {
    setLoading(true);
    const { email, password } = values;
    try {
      track_email_login_start({
        page: "homepage",
      });
      await login({ email, password });
      track_email_login_success({
        page: "homepage",
      });
      navigate("/user/profile");
    } catch (err) {
      track_email_login_fail({
        page: "homepage",
        error: err,
      });
      alert(err);
    } finally {
      setLoading(false);
    }
  };

  const readyForSubmit = (formik: FormikProps<EmailPasswordI>) => {
    const { email, password } = formik.values;
    const { email: emailError, password: passwordError } = formik.errors;
    return email && password && !emailError && !passwordError;
  };

  return (
    <Container>
      <ContentContainer>
        <AppTitle>Log In</AppTitle>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={existingUserSchema}
          onSubmit={handleSubmit}
        >
          {(formik: FormikProps<EmailPasswordI>) => (
            <Form>
              <BodyContainer>
                <BodySection>
                  <EmailPasswordForm formik={formik} />
                </BodySection>

                <AppLoadingButton
                  type="submit"
                  loading={loading}
                  disabled={loading || !readyForSubmit(formik)}
                  style={{ margin: "0 auto" }}
                >
                  Log In
                </AppLoadingButton>
              </BodyContainer>
              <RedirectLink to="/user/resetPassword">Forgot Password?</RedirectLink>
            </Form>
          )}
        </Formik>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  background-size: cover;
  overflow: hidden;
  position: relative;
  min-height: 800px;
  max-height: fit-content;
  height: 100vh;
  width: 100%;
`;

const ContentContainer = styled.section`
  max-width: 588px;
  margin: auto;
  margin-top: 250px;

  @media ${mobileSize} {
    margin-top: 172px;

    max-width: 327px;
  }
`;

const BodyContainer = styled.form`
  width: 100%;
  margin: auto;
  margin-top: 48px;
`;

const BodySection = styled.div`
  width: 100%;
  margin-bottom: 48px;
`;

const RedirectLink = styled(Link)`
  font-family: ${theme.SECONDARY_FONT};
  display: block;
  margin-top: 12px;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  text-decoration: underline;
  text-align: center;
  color: #8d8794;
  b {
    text-decoration: underline;
  }
`;
