import * as React from "react";
import styled from "styled-components";
import { AppTitle, AppText, Wrapper, AppLogo } from "../../../ui";
import { Link } from "gatsby";
import { mobileSize, laptopSize } from "../../../../util/variables";
// import { graphql, useStaticQuery } from "gatsby";
// import Img from "gatsby-image";
import { AppLink } from "../../../ui/AppLink";
import { web_acq_landing_page_click } from "../../../../services/mixpanel/acquisition-events";

import HeroDesktopB from "../../../../content/img/homepage/hero-image-desktop_b.webp";
import HeroMobileB from "../../../../content/img/homepage/hero-image-mobile_b.webp";

const HeroSegment: React.FC<{ specialDiscount?: boolean; offerParam?: string | null }> = ({
  specialDiscount = false,
  offerParam,
}) => {
  // const dataHero = useStaticQuery(graphql`
  //   query HeroImageQuery {
  //     heroMobile: file(relativePath: { eq: "homepage/hero-image-mobile_welcome.webp" }) {
  //       childImageSharp {
  //         fixed(quality: 100, width: 134, height: 388) {
  //           ...GatsbyImageSharpFixed_noBase64
  //         }
  //       }
  //     }
  //     heroDesktop: file(relativePath: { eq: "homepage/hero-image-desktop_welcome.webp" }) {
  //       childImageSharp {
  //         fixed(quality: 100, width: 449, height: 681) {
  //           ...GatsbyImageSharpFixed_noBase64
  //         }
  //       }
  //     }
  //   }
  // `);

  return (
    <HeroContainer>
      <StyledWrapper>
        <HeroContent>
          <LogoLink to="/">
            <AppLogo size="x-large" />
          </LogoLink>
          <LargeHeading>The world’s most important knowledge, visualized.</LargeHeading>
          <AppText style={{ maxWidth: "60%", marginBottom: 44 }}>
            Join millions of people learning on Imprint
          </AppText>
          <AppLink
            to={`/onboarding/questions${offerParam ? `?offer=${offerParam}` : ""}`}
            onClick={() => web_acq_landing_page_click("hero")}
          >
            Get Started
          </AppLink>
        </HeroContent>
        <HeroImgDiv>
          <picture >
            {/* media doesn't work with template literals */}
            <source srcSet={HeroMobileB} media="(max-width: 768px)" />
            <source srcSet={HeroDesktopB} media="(min-width: 768px)" />
            <img src={HeroDesktopB} alt="Hero Image" style={{ width: "100%" }} loading="eager" />
          </picture>
        </HeroImgDiv>
      </StyledWrapper>
    </HeroContainer>
  );
};

const StyledWrapper = styled(Wrapper as any)`
  position: relative;
  height: 100%;
  @media ${mobileSize} {
  }
`;

const HeroImgDiv = styled.div`
  position: absolute;
  top: 0;
  right: -5%;
  bottom: 0;
  min-height: 100%;
  max-height: 100%;
  z-index: 0;
  img {
    object-fit: contain;
    max-height: 100%;
    object-position: bottom right;
  }

  @media ${mobileSize} {
    img {
      max-width: 50vw;
    }
  }

  @media ${laptopSize} {
    img {
      position: relative;
      right: -20%;
    }
  }
  @media ${mobileSize} {
    img {
      position: relative;
      right: 0;
      top: 45%;
      transform: translateY(-50%);
      height: 60%;
      max-width: 50vw;
    }
  }
`;

const LargeHeading = styled(AppTitle as any)`
  font-size: 56px;
  line-height: 70px;
  margin-bottom: 24px;
  @media ${laptopSize} {
    font-size: 40px;
    line-height: 52px;
  }
  @media ${mobileSize} {
    font-size: 36px;
    line-height: 45px;
    max-width: 75%;
  }
`;

const HeroContainer = styled.div`
  background-color: #fafafa;
  background-size: cover;
  overflow: hidden;
  position: relative;
  height: 600px;
  width: 100%;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
  @media ${mobileSize} {
    height: auto;
  }
`;

const LogoLink = styled(Link)`
  margin-bottom: 48px;
  @media ${mobileSize} {
    /* margin-top: 40px; */
    margin-bottom: 20px;
    width: 100px;
  }
`;

const HeroContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  padding: 58px 0px 40px 0;
  /* gap: 40px; */
  width: 60%;
  height: 100%;
  z-index: 2;

  @media ${mobileSize} {
    width: 100%;
    padding-top: 40px; /* padding: 0px 0px 40px 0; */
  }
`;

export { HeroSegment };
