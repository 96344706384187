import React, { useContext, useState } from "react";
import { setMixpanelProperties } from "../../../services/mixpanel/mixpanel";
import { attributionSource } from "../../../content/static/onboardingQuestions";

const OnboardingContext = React.createContext({
  onboardingAnswers: {},
  addOnboardingAnswer: (answer: any) => {},
  updateOnboardingAnswer: (key: string, answer: any) => {},
  onboardingLandingPage: "",
  setOnboardingLandingPage: (value: string) => {},
  promoName: "",
  setPromoName: (value: string) => {},
  couponId: "",
  setCouponId: (id: string) => {},
  saveOnboardingDataToLocalStorage: () => {},
  customGroup: "",
  setCustomGroup: (value: string) => {},
  enableGeolocation: false,
  setEnableGeolocation: (value: boolean) => {},
  variant: "control",
  setVariant: (value: string) => {},
});

export const useOnboardingContext = () => {
  const res = useContext(OnboardingContext);
  if (!res) {
    console.error("onboarding context is used outside of provider");
  }

  return res;
};

export const OnboardingProvider = ({ children }) => {
  const [onboardingAnswers, setOnboardingAnswers] = useState({
    lucidIsaNewWayTolearn: "viewed",
    lucidHelps: "viewed",
    toGetStartedAnswerQuestions: "viewed",
    whichTopicsDoYouWant_3: undefined,
    lucidHelpsYouLearn: "viewed",
    howMuchTime_3: undefined,
    booksRequireTime: "viewed",
    lucidIsBiteSized: "viewed",
    quoteRevamped_1: "viewed",
    learningPathsMaps: undefined,
    learningIsTheBeginning: "viewed",
    usersSayLucidImprovedLife: "viewed",
    setGoal_2: undefined,
    quoteRevamped_2: "viewed",
  });
  const [onboardingLandingPage, setOnboardingLandingPage] = useState("");
  const [promoName, setPromoName] = useState("");
  const [couponId, setCouponId] = useState("");
  const [customGroup, setCustomGroup] = useState("");
  const [enableGeolocation, setEnableGeolocation] = useState(false);
  const [variant, setVariant] = useState("control");

  const addOnboardingAnswer = (answer: any) => {
    setOnboardingAnswers(prev => ({
      ...prev,
      ...answer,
    }));
  };

  const updateOnboardingAnswer = (key: string, answer: any) => {
    if (key === attributionSource) {
      setMixpanelProperties({
        AttributionSource: answer,
      });
    }

    setOnboardingAnswers(prev => ({
      ...prev,
      [key]: answer,
    }));
  };

  const saveOnboardingDataToLocalStorage = () => {
    localStorage.setItem("onboardingAnswers", JSON.stringify(onboardingAnswers));
    localStorage.setItem("onboardingLandingPage", onboardingLandingPage);
  };

  const OnboardingValue = {
    onboardingAnswers,
    addOnboardingAnswer,
    updateOnboardingAnswer,
    onboardingLandingPage,
    setOnboardingLandingPage,
    promoName,
    setPromoName,
    couponId,
    setCouponId,
    saveOnboardingDataToLocalStorage,
    customGroup,
    setCustomGroup,
    enableGeolocation,
    setEnableGeolocation,
    variant,
    setVariant,
  };

  return (
    <OnboardingContext.Provider value={OnboardingValue}>{children}</OnboardingContext.Provider>
  );
};
