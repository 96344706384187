import React, { useEffect } from "react";
import styled from "styled-components";
import { mobileSize } from "../../util/variables";
import { AppButton } from "../ui/AppButton";
import { AppText } from "../ui/AppText";
import { gift_page_view, next_page_view } from "../../services/mixpanel/gift-flow-events";
import theme from "../../util/theme";
import { graphql, navigate, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { AppTitle } from "../ui/AppTitle";

export const HowItWorks = ({ backgroundColor }: { backgroundColor?: string }) => {
  useEffect(() => {
    gift_page_view("GiftingStart");
  }, []);

  const handleGiftLinkClick = () => {
    next_page_view("GiftingStart");
    navigate("/gift/start");
  };

  const imageData = useStaticQuery(graphql`
    query giftingImages {
      gift: file(relativePath: { eq: "gift/gift.webp" }) {
        childImageSharp {
          fixed(quality: 100, height: 70, width: 70) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      rocket: file(relativePath: { eq: "gift/gift-rocket.webp" }) {
        childImageSharp {
          fixed(quality: 100, height: 78, width: 57) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      star: file(relativePath: { eq: "gift/gift-star.webp" }) {
        childImageSharp {
          fixed(quality: 100, height: 80, width: 85) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `);

  return (
    <ValuePropsContainer backgroundColor={backgroundColor}>
      <AppTitle component="h2" color="#10130C">How it works:</AppTitle>
      <Grid>
        <GridItem span={1}>
          <CircleIcon>
            <Img fixed={imageData.gift.childImageSharp.fixed} />
          </CircleIcon>
        </GridItem>
        <GridItem span={2}>
          <h3 style={{ color: "#10130C", margin: "0" }}>Personalize Your Gift</h3>
          <StyledAppText style={{ color: "#10130C" }}>
            Enter the recipient’s information and include a message.
          </StyledAppText>
        </GridItem>
        <GridItem span={1}>
          <CircleIcon>
            <Img fixed={imageData.star.childImageSharp.fixed} />
          </CircleIcon>
        </GridItem>
        <GridItem span={2}>
          <h3 style={{ color: "#10130C", margin: "0" }}>Checkout</h3>
          <StyledAppText style={{ color: "#10130C" }}>
            Add your payment details and complete your gift purchase. You’ll receive a confirmation
            email.
          </StyledAppText>
        </GridItem>
        <GridItem span={1}>
          <CircleIcon>
            <Img fixed={imageData.rocket.childImageSharp.fixed} />
          </CircleIcon>
        </GridItem>
        <GridItem span={2}>
          <h3 style={{ color: "#10130C" }}>Share the Gift of Knowledge</h3>
          <AppText style={{ color: "#10130C" }}>
            Your recipient will be notified via the email you provide and can start learning!
          </AppText>
        </GridItem>
      </Grid>
      <StyledAppButton style={{ color: "white", fontWeight: 500 }} onClick={handleGiftLinkClick}>
        Gift Imprint
      </StyledAppButton>
    </ValuePropsContainer>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
`;

const ValuePropsContainer = styled(Container as any) <{ backgroundColor: string }>`
  background-color: ${props => props.backgroundColor ?? theme.LIME};
  padding: 108px;
  @media ${mobileSize} {
    padding: 48px 24px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 96px repeat(2, 1fr);
  gap: 40px;
  align-items: center;
  max-width: 432px;
  margin-top: 44px;
  @media ${mobileSize} {
    grid-template-columns: 1fr;
    justify-center: center;
    gap: 0px;
    margin-top: 24px;
  }
`;

const GridItem = styled.div<{ span: number }>`
  grid-column: span ${props => props.span};
  font-weight: 500;
  font-size: 20px;
  @media ${mobileSize} {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    text-align: center;
    grid-column: span 1;
    /* margin-bottom: 12px; */
  }
`;

const StyledAppButton = styled(AppButton as any)`
  margin-top: 70px;
  @media ${mobileSize} {
    margin-top: 32px;
  }
`;

const CircleIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 96px;
  margin: 0 auto;
  border-radius: 999px;
  background-color: white;
  border: 1px solid ${theme.LIME};
  @media ${mobileSize} {
    margin-bottom: 12px;
  }
`;

const StyledAppText = styled(AppText as any)`
  margin: 0;
  @media ${mobileSize} {
    margin-bottom: 32px;
    max-width: 295px;
    text-align: center;
  }
`;
