import { initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updateEmail,
  updatePassword,
} from "firebase/auth";
import { navigate } from "gatsby-link";
import axios from "../services/axios";
import {
  GATSBY_BE_URL,
  GATSBY_FIREBASE_API_KEY,
  GATSBY_FIREBASE_APP_ID,
  GATSBY_FIREBASE_AUTH_DOMAIN,
  GATSBY_FIREBASE_DATABASE_URL,
  GATSBY_FIREBASE_MEASUREMENT_ID,
  GATSBY_FIREBASE_PROJECT_ID,
  GATSBY_FIREBASE_SENDER_ID,
  GATSBY_FIREBASE_STORAGE_BUCKET,
} from "../util/variables";
import {
  getMixpanelDistinctId,
  mixpanelIdentify,
  setMixpanelProperties,
} from "./mixpanel/mixpanel";
import { getRemoteConfig } from "firebase/remote-config";
import { getStorage } from "firebase/storage";

const lucidConfig = {
  apiKey: GATSBY_FIREBASE_API_KEY,
  authDomain: GATSBY_FIREBASE_AUTH_DOMAIN,
  databaseURL: GATSBY_FIREBASE_DATABASE_URL,
  projectId: GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: GATSBY_FIREBASE_SENDER_ID,
  appId: GATSBY_FIREBASE_APP_ID,
  measurementId: GATSBY_FIREBASE_MEASUREMENT_ID,
};

const lucidApp = initializeApp(lucidConfig);

const isBrowser = () => typeof window !== "undefined";

export const storage = isBrowser() ? getStorage(lucidApp) : null;
export const remoteConfig = isBrowser() ? getRemoteConfig(lucidApp) : null;
export const auth = isBrowser() ? getAuth(lucidApp) : null;
auth?.useDeviceLanguage();

// Currently only used for polywise-ff free subscription flow
export const register = async ({
  email,
  password,
  customer_id,
  subscription_type,
  fetch_token,
  product,
}: {
  email: string;
  password: string;
  customer_id: string;
  subscription_type: string;
  fetch_token: string;
  product: string;
}) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const mid = await getMixpanelDistinctId();

    await Promise.all([
      axios.post(
        `${GATSBY_BE_URL}/updateStripeData`,
        JSON.stringify({
          uid: res.user.uid,
          customer_id,
        }),
      ),
      axios.post(
        `${GATSBY_BE_URL}/setupNewFirebaseUser`,
        JSON.stringify({
          uid: res.user.uid,
          subscription_type,
          mixpanel_id: mid,
          product,
        }),
      ),
      updateUserOnboardingData({ uid: res.user.uid, email })
    ]);

    const revenuecatRes = await axios.post(
      `${GATSBY_BE_URL}/createRevenueCatUser`,
      JSON.stringify({
        uid: res.user.uid,
        customer_id,
        fetch_token,
      }),
    );

    const revenuecatData = await revenuecatRes.data;
    // console.log(revenuecatData);
    setMixpanelProperties(revenuecatData);

    localStorage.setItem("email", email);
    localStorage.setItem("uid", res.user.uid);
    return res;
  } catch (error) {
    console.log(error);
    throw new Error("user account already exists");
  }
};

export const registerGiftUser = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    await updateUserOnboardingData({ uid: res.user.uid, email });
    return res;
  } catch (error) {
    console.error(error);
    throw new Error("user account already exists");
  }
};

export const registerFree = async ({
  email,
  password,
  code,
}: {
  email: string;
  password: string;
  code: string;
}) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);

    axios.post(
      `${GATSBY_BE_URL}/grantFreeSubscription`,
      JSON.stringify({
        uid: res.user.uid,
        email,
        code,
      }),
    );

    const token = await res.user.getIdToken();
    localStorage.setItem("token", token);
    localStorage.setItem("email", email);
    localStorage.setItem("uid", res.user.uid);
    return res;
  } catch (error) {
    console.error(error);
    throw new Error("user account already exists");
  }
};

export const registerCoupon = async ({ email, password }: { email: string; password: string }) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);

    await updateUserOnboardingData({ uid: res.user.uid, email });

    const { uid } = res.user;
    localStorage.setItem("uid", uid);
    localStorage.setItem("email", email);
    return res;
  } catch (error) {
    console.log(error);
    switch (error.code) {
      case "auth/email-already-in-use":
        return { error: "User account already exist" };
      default:
        return { error: "Something went wrong while registering. Please try again later." };
    }
  }
};

const updateUserOnboardingData = async (data: { uid: string; email: string }) => {
  return axios.post(`${GATSBY_BE_URL}/updateUserOnboardingData`, JSON.stringify(data));
};

// DEPRECATED: was previously used for flow where user creates account after purchasing
// const updateStripeData = (uid: string, customer_id: string) => {
//   try {
//     axios.post(
//       `${GATSBY_BE_URL}/updateStripeData`,
//       JSON.stringify({
//         uid,
//         customer_id,
//       }),
//     );
//   } catch (err) {
//     console.error(err);
//     throw new Error(`updateStripeData error: ${err}`);
//   }
// };

// DEPRECATED: was previously used for flow where user creates account after purchasing
// const setupNewFirebaseUser = async (uid: string, subscription_type: string, product: string) => {
//   try {
//     const mid = await getMixpanelDistinctId();
//     axios.post(
//       `${GATSBY_BE_URL}/setupNewFirebaseUser`,
//       JSON.stringify({
//         uid,
//         subscription_type,
//         mixpanel_id: mid,
//         product,
//       }),
//     );
//   } catch (err) {
//     console.error("An error occurred during updateStripeData");
//     throw new Error(`setupNewFirebaseUser error: ${err}`);
//   }
// };

// DEPRECATED: was previously used for flow where user creates account after purchasing
// const createRevenueCatUser = async (uid: string, customer_id: string, fetch_token: string) => {
//   try {
//     const revenuecatRes = await axios.post(
//       `${GATSBY_BE_URL}/createRevenueCatUser`,
//       JSON.stringify({
//         uid,
//         customer_id,
//         fetch_token,
//       }),
//     );

//     const revenuecatData = await revenuecatRes.data;
//     return revenuecatData;
//   } catch (err) {
//     console.error(err);
//     throw new Error(`createRevenueCatUser error: ${err}`);
//   }
// };

// DEPRECATED
// const trackSpecialOfferSubscription = async (uid: string, product: string) => {
//   try {
//     const revenuecatRes = await axios.post(
//       `${GATSBY_BE_URL}/trackSpecialOfferSubscription`,
//       JSON.stringify({
//         uid,
//         subscription_type: "yearly",
//         product,
//       }),
//     );

//     const revenuecatData = await revenuecatRes.data;
//     return revenuecatData;
//   } catch (err) {
//     console.error("An error occurred during trackSpecialOfferSubscription");
//     throw new Error(`trackSpecialOfferSubscription error: ${err}`);
//   }
// };

// DEPRECATED: was previously used for flow where user creates account after purchasing
// export const setupSpecialOfferSubscription = async ({
//   customer_id,
//   fetch_token,
//   product,
//   uid,
// }: {
//   customer_id: string;
//   fetch_token: string;
//   product: string;
//   uid: string;
// }) => {
//   if (!uid) {
//     return { error: "no UID provided" };
//   }

//   try {
//     updateStripeData(uid, customer_id);
//     await trackSpecialOfferSubscription(uid, product);
//     const revCatData = await createRevenueCatUser(uid, customer_id, fetch_token);
//     setMixpanelProperties(revCatData);
//   } catch (err) {
//     console.log(err);
//     throw new Error(`Something went wrong while setting up subscription: ${err}`);
//   }
// };

export const login = async ({ email, password }: { email: string; password: string }) => {
  try {
    const res = await signInWithEmailAndPassword(auth, email, password);
    if (res) {
      const token = await res.user.getIdToken();
      mixpanelIdentify(email);
      localStorage.setItem("token", token);
      localStorage.setItem("email", email);
      localStorage.setItem("uid", res.user.uid);
      return res;
    }
  } catch (error) {
    console.error(error);
    throw new Error("invalid email / password");
  }
};

export const logout = async () => {
  signOut(auth);
  localStorage.clear();
  // when a user logs out, stop identifying them by their email
  const mixpanelId = getMixpanelDistinctId();
  mixpanelIdentify(mixpanelId);
  navigate("/");
};

export const getCurrentUser = () => {
  const email = isBrowser() ? localStorage.getItem("email") || "" : "";
  return email;
};

export const getUserIdToken = async () => {
  return auth?.currentUser?.getIdToken(/* forceRefresh */ true);
};

export const getUserEmail = () => {
  return auth?.currentUser?.email ?? localStorage.getItem("email");
};

export const getUserUID = () => {
  return auth?.currentUser?.uid ?? localStorage.getItem("uid");
};

export const getUserInfo = () => {
  if (auth?.currentUser) {
    return {
      email: auth.currentUser.email,
      uid: auth.currentUser.uid,
    };
  }
  return null;
};

export const getProviderData = () => {
  if (!auth?.currentUser) {
    return null;
  }

  const { providerData } = auth.currentUser;
  const { providerId } = providerData[0];
  return providerId.replace(/\.com$/, "");
};

export const checkUserExists = async (email: string) => {
  const res = await axios.post(
    `${GATSBY_BE_URL}/checkFirebaseEmailExists`,
    JSON.stringify({ email }),
  );

  console.log(res.data);
  return res.data;
};

export const getUserProfile = async () => {
  const email = await getUserEmail();
  const idToken = await getUserIdToken();
  if (!email || !idToken) {
    return false;
  }

  const res = await axios.post(
    `${GATSBY_BE_URL}/getUserProfile`,
    JSON.stringify({ token: idToken, email }),
  );
  if (res.data.error) {
    alert("Session got expired, please login again");
    logout();
    navigate("/user/login");
  } else {
    return res.data;
  }
};

export const updateUserEmail = async (email: string) => {
  if (!auth || !auth.currentUser) {
    throw new Error("Login session is expired. Please login again");
  }

  return updateEmail(auth.currentUser, email)
    .then(async () => {
      const token = await auth.currentUser?.getIdToken();
      if (token) {
        localStorage.setItem("token", token);
        localStorage.setItem("email", email);
      }

      return email;
    })
    .catch(error => {
      console.error(error);
      switch (error.code) {
        case "invalid-email":
          throw new Error("Invalid email address");
        case "auth/requires-recent-login":
          alert("Please login again to update your email");
          // WONT NEED IF WE ADD ADDITIONAL PROTECTION
          logout();
          navigate("/user/login");
          break;
        case "auth/email-already-in-use":
          throw new Error("Email already in use");
        case "auth/too-many-requests":
          throw new Error("Too many requests, please try again later");
        default:
          throw new Error(error.code);
      }
    });
};

export const updateUserPassword = async (password: string) => {
  if (!auth || !auth.currentUser) {
    throw new Error("Login session is expired. Please login again");
  }

  return updatePassword(auth.currentUser, password)
    .then(async () => {
      const token = await auth.currentUser?.getIdToken();
      if (token) {
        localStorage.setItem("token", token);
      }

      return { success: true };
    })
    .catch(error => {
      console.error(error);
      switch (error.code) {
        case "invalid-password":
          throw new Error("Invalid password");
        case "auth/requires-recent-login":
          alert("Please login again to update your password");
          // WONT NEED IF WE ADD ADDITIONAL PROTECTION
          logout();
          navigate("/user/login");
          break;
        case "auth/too-many-requests":
          throw new Error("Too many requests, please try again later");
        case "auth/internal-error":
          throw new Error("Something went wrong, please try again later");
        default:
          throw new Error(error.code);
      }

      return { success: false };
    });
};

export const resetPassword = async (email: string) => {
  return axios.post(`${GATSBY_BE_URL}/triggerResetPasswordEmail`, JSON.stringify({ email }));
};

export const groupSignup = async (groupId: string, uid?: string) => {
  const userId = uid ?? getUserUID();
  return axios.post(
    `${GATSBY_BE_URL}/addGroupSignupSubscription`,
    JSON.stringify({ uid: userId, groupId }),
  );
};

// DEPRECATED: was previously used for flow where user creates account after purchasing
// export const saveUserData = async (email: string) => {
//   const storedUtm = localStorage.getItem("utmData");
//   const storedAnswers = localStorage.getItem("onboardingAnswers");
//   const storedLandingPage = localStorage.getItem("onboardingLandingPage") ?? "";

//   const utm = storedUtm ? JSON.parse(storedUtm) : {};
//   const onboardingAnswers = storedAnswers ? JSON.parse(storedAnswers) : {};
//   const mid = getMixpanelDistinctId();

//   const data = {
//     ...utm,
//     onboardingAnswers,
//     landingPage: storedLandingPage,
//     email,
//     mixpanel_id: mid,
//   };

//   return axios.post(`${GATSBY_BE_URL}/setUserWebAttribute`, JSON.stringify(data));
// };

// used for the special offer flow
export const saveUserOnboardingData = async (data: {
  uid: string;
  email: string;
  onboardingAnswers: object;
  userWebData: object;
  universalControlGroup: number;
}) => {
  return axios.post(`${GATSBY_BE_URL}/setUserAttributesAndWebData`, JSON.stringify(data));
};

/**
 * In free page account creation (polywise-ff) check if admin password is correct
 * @param password form admin password
 * @returns {boolean} true if password is correct, false if incorrect
 */
export const validateAdminPassword = async (password: string, email: string) => {
  if (!password || !email) {
    throw new Error("email and code is required");
  }

  try {
    const res = await axios.post(
      `${GATSBY_BE_URL}/validateAdminAccount`,
      JSON.stringify({
        code: password,
        email,
      }),
    );

    return res.data;
  } catch (error) {
    console.error(error);
    // throw new Error(error.response.data.error);
    throw new Error("Incorrect code or code already redeemed");
  }
};

export const connectAccountToSubscription = async ({
  uid,
  customer_id,
  fetch_token,
}: {
  uid: string;
  customer_id: string;
  fetch_token: string;
}) => {
  try {
    console.log("start connection");
    axios.post(
      `${GATSBY_BE_URL}/updateStripeData`,
      JSON.stringify({
        uid,
        customer_id,
      }),
    );

    const res = await axios.post(
      `${GATSBY_BE_URL}/createRevenueCatUser`,
      JSON.stringify({
        uid,
        customer_id,
        fetch_token,
      }),
    );
    // console.log(await res.data);
    console.log(res.data ? "subscription connected" : "something went wrong");
  } catch (err) {
    console.error(err);
    alert("Something went wrong while connecting subscription");
  }
};
