import * as React from "react";
import { BasicLayout } from "../layouts/BasicLayout";
import { useOnboardingContext } from "../components/PageSegments/onboarding/OnboardingContext";
import { web_acq_landing_page_view } from "../services/mixpanel/acquisition-events";
import { fbq } from "../services/pixel";
import { setMixpanelProperties } from "../services/mixpanel/mixpanel";
import { ONBOARDING_VARIANT_DATA, WEB_SKILLS_ONBOARDING_TEST_NAME } from "./special-welcome";
import { useABTest } from "../hooks/useABTest";
import SimplifiedLandingPage from "../ab-tests/SimplifiedLandingPage";
// used for Google traffic
export default (data: any) => {
  const { setOnboardingLandingPage } = useOnboardingContext();
  const variant = useABTest(ONBOARDING_VARIANT_DATA);

  const urlParams = new URLSearchParams(data.location.search);
  const fromParam = urlParams.get("from") ?? undefined;
  const gclidParam = urlParams.get("gclid") ?? undefined;

  React.useEffect(() => {
    web_acq_landing_page_view();
    fbq("trackCustom", "landing_page_view");
    setOnboardingLandingPage("web_discount_flow");
  }, [setOnboardingLandingPage, fromParam]);

  React.useEffect(() => {
    if (gclidParam) {
      setMixpanelProperties({
        gclid: gclidParam,
      });
    }
  }, [gclidParam]);

  React.useEffect(() => {
    if (variant === "control") {
      localStorage.setItem(WEB_SKILLS_ONBOARDING_TEST_NAME, "simplified");
    }
  }, [variant]);

  return (
    <BasicLayout>
      <SimplifiedLandingPage />
    </BasicLayout>
  );
};
