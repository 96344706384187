import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { mobileSize } from "../../../../util/variables";
import { TopBanner } from "../../../ui/TopBanner";
import theme from "../../../../util/theme";
import { useLocalPriceContext } from "../../../../providers/LocalPriceProvider";

export const SpecialOfferBar = ({
  customGroup,
  trial = true,
  offerParam,
}: {
  customGroup?: string;
  trial?: boolean;
  offerParam?: string | null;
}) => {
  const { getDiscountPercent, specialDiscount } = useLocalPriceContext();

  return (
    <div>
      {customGroup ? (
        <TopBanner>
          {customGroup ? customGroup + ":" : ""} Try Imprint free for 7 <MobileBr />
          days and get{" "}
          <Link
            to={`/onboarding/questions${offerParam ? `?offer=${offerParam}` : ""}`}
            style={{ fontWeight: 500 }}
          >
            {getDiscountPercent()}% OFF
          </Link>
        </TopBanner>
      ) : (
        <TopBanner>
          Try Imprint {trial ? "free for 7 days" : "now"} and get{" "}
          <MobileBr />
          {getDiscountPercent()}% off.{" "}
          <Link
            to={`/onboarding/questions${offerParam ? `?offer=${offerParam}` : ""}`}
            style={{ fontWeight: 500, color: theme.WHITE_COLOR }}
          >
            Get Started
          </Link>
        </TopBanner>
      )}
    </div>
  );
};

const MobileBr = styled.br`
  display: none;
  @media ${mobileSize} {
    display: block;
  }
`;
