import React from "react";
import styled from "styled-components";
import { laptopSize, mobileSize } from "../../util/variables";
import theme from "../../util/theme";
import { AppTitle } from "./AppTitle";

interface ImageTextSectionProps {
  title: string;
  reverse?: boolean;
  mobileReverse?: boolean;
  content: React.ReactNode;
  img: {
    alt: string;
    src: string;
  };
  bgColor?: string;
  bottomContent?: React.ReactNode;
}

export const ImageTextSection: React.FC<ImageTextSectionProps> = ({
  reverse = false,
  mobileReverse = false,
  img,
  title,
  content,
  bgColor,
  bottomContent,
}) => {
  return (
    <Section bg={bgColor}>
      <ContentContainer reverse={reverse} mobileReverse={mobileReverse}>
        <StyledImg src={img.src} alt={img.alt} width={368} height={368} loading="lazy" />
        <div style={{ maxWidth: 554 }}>
          <H2 reverse={reverse}>{title}</H2>
          <Content reverse={reverse}>{content}</Content>
        </div>
      </ContentContainer>
      {bottomContent}
    </Section>
  );
};

const H2 = styled(AppTitle as any).attrs(() => ({
  as: "h2",
}))`
  font-size: 36px;
  line-height: 40px;
  text-align: ${props => (props.reverse ? "right" : "left")};
  @media ${laptopSize} {
    text-align: left;
  }
  @media ${mobileSize} {
    font-size: 24px;
    line-height: 28px;
  }
`;

const Section = styled.section<{ bg?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  padding: 80px 120px;
  background: ${props => props.bg ?? theme.WHITE_COLOR};

  @media ${mobileSize} {
    padding: 48px 24px;
  }
`;

const ContentContainer = styled.div<{ reverse: boolean; mobileReverse: boolean }>`
  display: flex;
  flex-direction: ${props => (props.reverse ? "row-reverse" : "row")};
  align-items: center;
  gap: 106px;

  @media ${laptopSize} {
    flex-direction: ${props => (props.mobileReverse ? "column-reverse" : "column")};
    gap: 32px;
  }
`;

const StyledImg = styled.img`
  max-width: 368px;
  max-height: 368px;
  object-fit: contain;

  @media ${mobileSize} {
    width: 327px;
    height: max-content;
  }
`;

const Content = styled.div<{ reverse: boolean }>`
  text-align: ${props => (props.reverse ? "right" : "left")};
  margin: 0;
  @media ${laptopSize} {
    text-align: left;
  }
`;
