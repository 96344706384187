import React from "react";
import GlobalFonts from "./src/fonts/Fonts";
import { OnboardingProvider } from "./src/components/PageSegments/onboarding/OnboardingContext";
import { RemoteConfigProvider } from "./src/providers/RemoteConfigProvider";
import { LocalPriceProvider } from "./src/providers/LocalPriceProvider";
import { isDevEnvironment } from "./src/util/misc";
import { saveUTMMixpanelProperties } from "./src/services/mixpanel/mixpanel";

export const wrapRootElement = ({ element }) => {
  isDevEnvironment();
  saveUTMMixpanelProperties();

  return (
    <>
      <GlobalFonts />
      <RemoteConfigProvider>
        <OnboardingProvider>
          <LocalPriceProvider>{element}</LocalPriceProvider>
        </OnboardingProvider>
      </RemoteConfigProvider>
    </>
  );
};

export const onClientEntry = () => {
  if (typeof window === "undefined") return;

  const script = document.createElement("script");
  script.async = true;
  script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-WPQ2RBB');`;
  document.head.insertBefore(script, document.head.firstChild);
};

export const onRouteUpdate = ({ location }) => {
  // Don"t track while developing.
  if (process.env.NODE_ENV === `production` && typeof fbq === `function`) {
    fbq("track", "ViewContent");
  }
};
