import React from "react";
import { Helmet } from "react-helmet";
import IndexLayout from "./index";
import favicon from "../content/img/icon/favicon.ico";
import theme from "../util/theme";
import { graphql, useStaticQuery } from "gatsby";
interface BasicLayout {
  title?: string;
  noIndex?: boolean;
  noFollow?: boolean;
  themeColor?: string;
  children: React.ReactNode;
}
export const BasicLayout: React.FC<BasicLayout> = ({
  title,
  noIndex,
  noFollow,
  children,
  themeColor,
}) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          twitterUsername
          image
          siteUrl
        }
      }
    }
  `);

  // console.log(data.site.siteMetadata);
  return (
    <IndexLayout>
      <Helmet>
        <title>{title ? title : "Imprint: Learn Visually"}</title>
        <meta
          name="description"
          content="Imprint - The world’s most important knowledge, visualized."
        />
        <html lang="en-US" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta name="theme-color" content={themeColor ?? theme.PRIMARY_COLOR} />
        <meta charSet="UTF-8" />
        <meta name="og:image" content={data.site.siteMetadata.image} />
        <meta name="og:image:width" content="1000" />
        <meta name="og:image:height" content="667" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Imprint: Learn Visually" />
        <meta
          name="twitter:description"
          content="Imprint - The world’s most important knowledge, visualized."
        />
        {/* <meta name="twitter:image" content={data.site.siteMetadata.image} /> */}
        <meta
          name="twitter:image"
          content="https://storage.googleapis.com/know-d8bc0.appspot.com/imprint-website-preview-image.png"
        />

        {noIndex && noFollow ? (
          <meta name="robots" content="noindex,nofollow" />
        ) : noIndex || noFollow ? (
          <meta name="robots" content={noIndex ? "noindex" : "nofollow"} />
        ) : null}

        <link rel="canonical" href={data.site.siteMetadata.image.siteUrl} />
        <link rel="icon" href={favicon} />
      </Helmet>
      {children}
    </IndexLayout>
  );
};

export const HappinessLayout: React.FC<BasicLayout> = ({ title, noIndex, children }) => (
  <IndexLayout>
    <Helmet>
      <title>{title ? title : "Imprint"}</title>
      <link rel="icon" href={favicon} />
      <meta
        name="description"
        content="Imprint - The world’s most important knowledge, visualized."
      />
      <html lang="en-US" />
      <meta name="theme-color" content={theme.PRIMARY_COLOR} />
      <link rel="canonical" href="https://imprintapp.com/" />
      <meta charSet="UTF-8" />
      <meta name="og:image" content="/static/imprint-website-preview-image.png" />
      <meta name="og:image:width" content="1000" />
      <meta name="og:image:height" content="667" />
      {noIndex && <meta name="googlebot" content="noindex" />}

      {/* <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
      <link
        href="https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap"
        rel="stylesheet"
      /> */}
    </Helmet>
    {children}
  </IndexLayout>
);
