import React, { useContext, useEffect, useState } from "react";
import {
  FreeTrialSegment,
  PrepaymentHero,
  RatingSegment,
  WhatsInsideSegment,
  LibrarySegment,
} from "../../components/PageSegments/special-offer/prepayment";
import { BasicLayout } from "../../layouts/BasicLayout";
import { getStripeUrlV2 } from "../../actions/stripeAction";
import { navigate } from "gatsby";
import { BottomNavSegment } from "../../components/PageSegments";
import { saveUserOnboardingData } from "../../services/firebase";
import {
  web_acq_prepayment_attempt,
  web_acq_prepayment_failure,
  web_acq_prepayment_success,
  web_acq_prepayment_view,
} from "../../services/mixpanel/acquisition-events";
import { fbq } from "../../services/pixel";
import {
  Subscribe_Account_AccountCreationSuccess,
  setMixpanelProperties,
} from "../../services/mixpanel/mixpanel";
import { useOnboardingContext } from "../../components/PageSegments/onboarding/OnboardingContext";
import { baseUrl, BRAZE_API_KEY, BRAZE_SDK_ENDPOINT, COUPON_IDS } from "../../util/variables";
import { setOneSignalAttributes } from "../../services/onesignal";
import { triggerCustomEvent } from "../../services/google-tag-manager";
import { GTM_CUSTOM_EVENT } from "../../util/types";
import { WEB_NO_TRIAL_FLOW } from "../join";
import { PrepaymentHeroNoTrial } from "../../components/PageSegments/special-offer/prepayment/PrepaymentHeroNoTrial";
import { NoTrialSegment } from "../../components/PageSegments/special-offer/prepayment/NoTrialSegment";
import { LocalPriceContext } from "../../providers/LocalPriceProvider";
import { useAuth } from "../../hooks/useAuth";
import { useABTest } from "../../hooks/useABTest";

const FREE_TRIAL_ID = "free-trial-2";
const NO_TRIAL_ID = "no-trial-2";
const NO_TRIAL_FLOW = "nt";
export const CHECKOUT_URL_KEY = "stripeCheckoutURL";

export const PREPAYMENT_APPLE_PAY_TEST_NAME = "202501_PrepaymentApplePay";
const PREPAYMENT_APPLE_PAY_TEST_VARIANTS = {
  testName: PREPAYMENT_APPLE_PAY_TEST_NAME,
  variants: { control: "control", variant: "variant" },
  weights: { control: 0.5, variant: 0.5 },
};

export default (data: any) => {
  const [stripeURL, setStripeURL] = useState("");
  const { currentPrice: localPrice, specialDiscount } = useContext(LocalPriceContext);
  const { user, loading: loadingUser } = useAuth();
  const variant = useABTest(PREPAYMENT_APPLE_PAY_TEST_VARIANTS);
  const isWalletVariant = variant === "variant";
  const [trackingComplete, setTrackingComplete] = useState(false);
  const [redirectClicked, setRedirectClicked] = useState(false);

  const { search, state } = data.location;
  const urlParams = new URLSearchParams(search);

  // url params used for stripe cancel
  const flowParam = urlParams.get("flow");

  const { onboardingAnswers } = useOnboardingContext();

  const isNoTrial =
    (typeof window !== "undefined" &&
      sessionStorage.getItem("landing_page") === WEB_NO_TRIAL_FLOW) ||
    flowParam === NO_TRIAL_FLOW;

  const getStripeCheckoutUrl = () => {
    fbq("track", "InitiateCheckout");
    triggerCustomEvent(GTM_CUSTOM_EVENT.WEB_ACQ_PREPAYMENT_SUCCESS);
    web_acq_prepayment_success();
    setTimeout(() => {
      document.location.href = stripeURL;
    }, 100);
  };

  // const getUTMDataFromLocalStorage = () => {
  //   const utmData = localStorage.getItem("UTM");
  //   return utmData ? JSON.parse(utmData) : {};
  // };

  const handleRedirect = (e: React.MouseEvent<HTMLButtonElement>) => {
    const ctaType = e.currentTarget.getAttribute("data-cta-type");
    web_acq_prepayment_attempt(variant ?? "", ctaType ?? "");
    setRedirectClicked(true);
  };

  const forwardToNextPage = () => {
    getStripeCheckoutUrl();
  };

  // Post Account creation set up 3rd party tracking and analytics
  useEffect(() => {
    const trackNewUser = async (uid: string, email: string, onboardingAnswers: object) => {
      const price = localPrice.price_id;
      const universalControlGroup = Math.floor(Math.random() * 10) + 1;
      const storedUtm = localStorage.getItem("utmData");
      localStorage.removeItem("gclid");

      const promises = [
        import("../../services/braze").then(({ initialize, changeUser, getUser, openSession }) => {
          initialize(BRAZE_API_KEY, { baseUrl: BRAZE_SDK_ENDPOINT });
          openSession();
          changeUser(uid);
          const user = getUser();
          if (user) {
            user.setEmail(email);
            user.setCustomUserAttribute("PrepaymentABPrice", price ?? "no price found");
            user.setCustomUserAttribute("customer_pool_id", universalControlGroup);
            console.log("Braze user and email has been set");
          }
        }),
        setMixpanelProperties({ AccountCreatedPlatform: "Web" }, true),
        setOneSignalAttributes(uid, email),
        Subscribe_Account_AccountCreationSuccess(email, uid),
        saveUserOnboardingData({
          uid,
          email,
          onboardingAnswers,
          userWebData: storedUtm ? JSON.parse(storedUtm) : {},
          universalControlGroup,
        }),
        fbq("track", "CompleteRegistration"),
      ];

      try {
        await Promise.all(promises);
      } catch (error) {
        console.error("Error during user tracking:", error);
      } finally {
        setTrackingComplete(true);
      }
    };

    if (state?.uid && state?.email) {
      console.log("email", state.email);
      trackNewUser(state.uid, state.email, onboardingAnswers);
    } else {
      setTrackingComplete(true);
    }
  }, []);

  useEffect(() => {
    const getStripeURLs = async () => {
      web_acq_prepayment_view();
      triggerCustomEvent(GTM_CUSTOM_EVENT.WEB_ACQ_PREPAYMENT_VIEW);

      const savedCheckoutUrl = sessionStorage.getItem(CHECKOUT_URL_KEY);
      if (savedCheckoutUrl) {
        setStripeURL(savedCheckoutUrl);
        return;
      }

      try {
        const email = state?.email ?? user?.email;
        const uid = state?.uid ?? user?.uid;
        const price_id = isNoTrial ? localPrice.price_id_no_trial : localPrice.price_id;

        if (!email) {
          throw new Error("We require email in this flow to create a checkout session");
        }

        const stripeURL = await getStripeUrlV2({
          email,
          uid,
          cancel_url: `${baseUrl}/special-offer${search}`,
          success_url: `${baseUrl}/special-offer/complete?session_id={CHECKOUT_SESSION_ID}&uid=${uid}`,
          mode: "subscription",
          priceId: price_id ?? "",
          currency: localPrice.currency.code,
          discounts: [{ coupon: specialDiscount ?? COUPON_IDS.percent20 }],
          ...(!isNoTrial && { subscription_data: { trial_period_days: 7 } }),
        });

        setStripeURL(stripeURL);
        sessionStorage.setItem(CHECKOUT_URL_KEY, stripeURL);
      } catch (err) {
        console.error(err);
        web_acq_prepayment_failure();
        // eslint-disable-next-line no-alert
        alert(
          "Something went wrong getting your checkout link. Please try again going through onboarding again.",
        );

        navigate(flowParam === "startnow" ? "/startnow" : "/");
      }
    };

    if (!loadingUser) {
      getStripeURLs();
    }
  }, [localPrice.price_id, loadingUser]);

  useEffect(() => {
    if (redirectClicked && Boolean(stripeURL) && trackingComplete) {
      forwardToNextPage();
    }
  }, [redirectClicked, stripeURL, trackingComplete]);

  return (
    <BasicLayout noIndex>
      <div>
        {isNoTrial ? (
          <PrepaymentHeroNoTrial
            priceData={localPrice}
            handleRedirect={handleRedirect}
            loading={redirectClicked}
            isWalletVariant={isWalletVariant}
          />
        ) : (
          <PrepaymentHero
            priceData={localPrice}
            handleRedirect={handleRedirect}
            loading={redirectClicked}
            isWalletVariant={isWalletVariant}
          />
        )}
        <RatingSegment scrollId={isNoTrial ? NO_TRIAL_ID : FREE_TRIAL_ID} />
        <WhatsInsideSegment scrollId={isNoTrial ? NO_TRIAL_ID : FREE_TRIAL_ID} />
        <LibrarySegment />
        {isNoTrial ? (
          <NoTrialSegment
            id={NO_TRIAL_ID}
            priceData={localPrice}
            handleRedirect={handleRedirect}
            loading={redirectClicked}
            isWalletVariant={isWalletVariant}
          />
        ) : (
          <FreeTrialSegment
            id={FREE_TRIAL_ID}
            priceData={localPrice}
            handleRedirect={handleRedirect}
            loading={redirectClicked}
            isWalletVariant={isWalletVariant}
          />
        )}
        <BottomNavSegment />
      </div>
    </BasicLayout>
  );
};
