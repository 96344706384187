import React from "react";
import styled from "styled-components";
import ApplePayIcon from "../../content/assets/icons/apple-pay-logo.svg";
import GooglePayIcon from "../../content/assets/icons/google-pay-logo.svg";
import { OperatingSystem, getOperatingSystem } from "../../util/misc";

function checkApplePaySupported(os: OperatingSystem) {
  switch (os) {
    case "ios":
    case "macos":
    case "unknown":
      return true;
    case "android":
    case "linux":
    case "windows":
      return false;
    default:
      return true;
  }
}

const DigitalWalletIcon = () => {
  const os = getOperatingSystem();
  const isApplePaySupported = checkApplePaySupported(os);

  return (
    <Container>
      <img
        src={isApplePaySupported ? ApplePayIcon : GooglePayIcon}
        alt={isApplePaySupported ? "Apple Pay" : "Google Pay"}
        width={34}
        height={14}
      />
    </Container>
  );
};

const Container = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 6px;
  border-radius: 6px;
  border: 1px solid #D9D9D9;
  background-color: #fff;
`;

export default DigitalWalletIcon;
