import * as React from "react";
import { FlexDiv, NavBar } from "../components/ui/";
import { OurTeamSegment, WeAreImprintSegment, WorkHereSegment } from "../components/PageSegments";
import { BottomNavSegment } from "../components/PageSegments/BottomNav";
import { BasicLayout } from "../layouts/BasicLayout";

export default () => {
  return (
    <BasicLayout>
      <FlexDiv as="main" direction="column" justify="flex-start" align="center">
        <NavBar initiallyWhite />
        <WeAreImprintSegment />
        <OurTeamSegment />
        <WorkHereSegment />
        <BottomNavSegment />
      </FlexDiv>
    </BasicLayout>
  );
};
