import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { BasicLayout } from "../../layouts/BasicLayout";
import { baseUrl, DEFAULT_CURRENCY_CODE, mobileSize } from "../../util/variables";
import { AppLogo, AppTitle, AppButton, FlexDiv } from "../../components/ui";
import StepIndicator from "../../components/gift/StepIndicator";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import theme from "../../util/theme";
import { getStripeUrlV2 } from "../../actions/stripeAction";
import { LocalPriceContext } from "../../providers/LocalPriceProvider";
import { DYNAMIC_GIFT_PRICE_IDS } from "../../util/local-price-ids";
// import { getNumericGiftPrice } from "../../util/static-price-data";
import { gift_page_view, gift_purchase_start } from "../../services/mixpanel/gift-flow-events";
import { getNumericGiftPrice } from "../../util/static-price-data";
import { fbq } from "../../services/pixel";
import LoadingSpinner from "../../components/ui/LoadingSpinner";

interface FormValues {
  senderFirstName: string;
  senderLastName: string;
  senderEmail: string;
  firstName: string;
  lastName: string;
  recipientEmail: string;
  giftMessage: string;
}
const MAX_MESSAGE_LENGTH = 500;
const validationSchema = Yup.object().shape({
  senderFirstName: Yup.string().required("Name is required"),
  senderLastName: Yup.string().required("Name is required"),
  senderEmail: Yup.string().email("Invalid email").required("Email is required"),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  recipientEmail: Yup.string().email("Invalid email").required("Email is required"),
  giftMessage: Yup.string().max(MAX_MESSAGE_LENGTH, "Message must be 1000 characters or less"),
});

const Start = () => {
  const { location } = useContext(LocalPriceContext);
  const currency_code = location?.currency.code ?? DEFAULT_CURRENCY_CODE;
  const [loading, setLoading] = useState(false);
  const initialValues = {
    senderFirstName: "",
    senderLastName: "",
    senderEmail: "",
    firstName: "",
    lastName: "",
    recipientEmail: "",
    giftMessage: "",
  };

  useEffect(() => {
    gift_page_view("GiftPersonalize");
  }, []);

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);
    fbq("track", "InitiateCheckout");
    gift_purchase_start();
    const metadata = {
      order_type: "gift_coupon",
      gifted_by: values.senderEmail,
      gifted_to: values.recipientEmail,
      gift_to_first_name: values.firstName,
      gift_to_last_name: values.lastName,
      sender_name: `${values.senderFirstName} ${values.senderLastName}`,
      gift_message: values.giftMessage,
      original_cost: getNumericGiftPrice(currency_code),
    };
    try {
      const url = await getStripeUrlV2({
        // discounts: [
        //   { coupon: "3davheui" }, was used for 50% discount
        // ],
        priceId: DYNAMIC_GIFT_PRICE_IDS[99.99],
        email: values.senderEmail,
        success_url: `${baseUrl}/gift/complete`,
        cancel_url: `${baseUrl}/gift/start`,
        currency: currency_code,
        mode: "payment",
        payment_intent_data: {
          metadata,
        },
      });
      // Redirect to next step
      if (url) {
        window.location.href = url;
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <BasicLayout>
      <Container>
        <LogoContainer>
          <AppLogo />
        </LogoContainer>
        <StepIndicator numSteps={3} currentStep={1} />
        <H1 style={{ marginTop: 80, color: theme.BLACK_COLOR }}>Step 1: Personalize</H1>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting }) => {
            return (
              <StyledForm>
                <Section>
                  <H2>Your Information</H2>
                  <Grid>
                    <FormGroup>
                      <StyledField name="senderFirstName" placeholder="First Name" />
                      <ErrorMessage name="senderFirstName" component="div" />
                    </FormGroup>
                    <FormGroup>
                      <StyledField name="senderLastName" placeholder="Last Name" />
                      <ErrorMessage name="senderLastName" component="div" />
                    </FormGroup>
                    <FormGroup>
                      <StyledField name="senderEmail" type="email" placeholder="Your Email" />
                      <ErrorMessage name="senderEmail" component="div" />
                    </FormGroup>
                  </Grid>
                </Section>
                <Section>
                  <H2>Recipient Information</H2>
                  <Grid>
                    <FormGroup>
                      <StyledField name="firstName" placeholder="First Name" />
                      <ErrorMessage name="firstName" component="div" />
                    </FormGroup>
                    <FormGroup>
                      <StyledField name="lastName" placeholder="Last Name" />
                      <ErrorMessage name="lastName" component="div" />
                    </FormGroup>
                    <FormGroup>
                      <StyledField
                        name="recipientEmail"
                        type="email"
                        placeholder="Recipient Email"
                      />
                      <ErrorMessage name="recipientEmail" component="div" />
                    </FormGroup>
                  </Grid>
                </Section>
                <Section>
                  <H2>Gift Message (optional)</H2>
                  <FormGroup>
                    <Field name="giftMessage" as="textarea" placeholder="Message" />
                    <span>
                      {values.giftMessage.length}/{MAX_MESSAGE_LENGTH}
                    </span>
                    <ErrorMessage name="giftMessage" component="div" />
                  </FormGroup>
                </Section>
                <FlexDiv justify="center">
                  <AppButton type="submit" disabled={isSubmitting || loading}>
                    {loading ? (
                      <FlexDiv justify="center" align="center">
                        <LoadingSpinner color="white" />
                      </FlexDiv>
                    ) : (
                      "Continue"
                    )}
                  </AppButton>
                </FlexDiv>
              </StyledForm>
            );
          }}
        </Formik>
      </Container>
    </BasicLayout>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  padding: 80px 64px;
  @media ${mobileSize} {
    padding: 0px 24px 24px 24px;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  left: 100px;
  top: 80px;
  display: block;
  @media ${mobileSize} {
    position: static;
    margin-top: 64px;
    margin-bottom: 32px;
    height: 20px;
    width: 80px;
  }
`;

const H1 = styled(AppTitle as any)`
  margin-top: 100px;
  margin-bottom: 0;
  @media ${mobileSize} {
    margin-top: 32px;
  }
`;

const H2 = styled(AppTitle as any).attrs(() => ({
  component: "h2",
}))`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 24px;
  font-family: ${theme.SECONDARY_FONT};
  color: ${theme.BLACK_COLOR};
  @media ${mobileSize} {
    text-align: center;
  }
`;

const StyledForm = styled(Form)`
  width: 100%;
  max-width: 600px;
`;

const StyledField = styled(Field)`
  &:focus {
    outline: 2px solid #20614c;
  }
`;

const Section = styled.div`
  margin-bottom: 32px;
  margin-top: 56px;
  @media ${mobileSize} {
    text-align: center;
    margin-top: 32px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;

  @media ${mobileSize} {
    grid-template-columns: 1fr;
  }
`;

const FormGroup = styled.div`
  /* margin-bottom: 1rem; */

  input,
  textarea {
    width: 100%;
    border: 2px solid #d2d5d8;
    border-radius: 8px;
  }

  input {
    padding: 20px 16px;
  }

  textarea {
    padding: 12px 18px;
  }

  textarea {
    height: 100px;
    resize: vertical;
  }

  div {
    color: red;
    font-size: 0.8rem;
    margin-top: 0.2rem;
  }

  span {
    display: block;
    margin-right: 12px;
    text-align: right;
    font-size: 12px;
    line-height: 0.5;
  }
`;

export default Start;
