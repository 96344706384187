import React from "react";
import { FlexDiv } from "../../../ui";
import theme from "../../../../util/theme";
import styled from "styled-components";
import { AppLoadingButton, Wrapper } from "../../../ui";
import { mobileSize } from "../../../../util/variables";
import StarsSrc from "../../../../content/assets/StarsBlack.svg";
import { OfferGoSegmentI } from "../../../../hooks/useOffer";
import AuthStatusMessage from "./AuthStatusMessage";

interface StatisticProps {
  title: string;
  statistic: string;
  unit: React.ReactNode;
  footer: string;
}

const Statistic: React.FC<StatisticProps> = ({
  title,
  statistic,
  unit,
  footer,
}) => {
  return (
    <StatContainer>
      <FlexDiv direction="column" gap={24}>
        <h2>{title}</h2>
        <FlexDiv direction="column" gap={22}>
          <StatMainContent>
            <span>{statistic}</span>
          </StatMainContent>
          <StatSecondaryContent>
            {unit}
          </StatSecondaryContent>
        </FlexDiv>
        <p>{footer}</p>
      </FlexDiv>
    </StatContainer>
  );
};

export const StatsSection: React.FC<OfferGoSegmentI> = ({ priceData, buttonClicked, handleClick }) => {
  return (
    <Section>
      <Wrapper>
        <FlexDiv direction="column" align="center" gap={24}>
          <h1>Invest in yourself</h1>
          <StatsContainer>
            <Statistic
              title="Learn something new in"
              statistic="2"
              unit="minutes a day"
              footer="Fit bite-sized lessons into your day and retain what you learn"
            />
            <Statistic
              title="Average rating:"
              statistic="4.8"
              unit={<Stars src={StarsSrc} />}
              footer="Over 30,000 reviews in the App Store and Play Store"
            />
            <Statistic
              title="Imprint makes learning easy for"
              statistic="96%"
              unit="of regular users*"
              footer="*Based on a survey of users who used Imprint 3 times a week"
            />
          </StatsContainer>
          <FlexDiv direction="column" align="center">
            <AppLoadingButton
              type="button"
              loading={buttonClicked}
              disabled={false}
              style={{ fontSize: 18 }}
              onClick={() => handleClick("stats")}
            >
              Claim Offer
            </AppLoadingButton>
            <AuthStatusMessage priceData={priceData} />
          </FlexDiv>
        </FlexDiv>
      </Wrapper>
    </Section>
  );
};

const StatsContainer = styled.div`
  display: flex;
  gap: 20px;
  padding: 0 10% 0 10%;

  @media ${mobileSize} {
    flex-direction: column;
    max-width: 100%;
    gap: 16px;
  }
`;

const Stars = styled.img`
  @media ${mobileSize} {
    width: 129px;
    height: 24px;
  }
  fill: ${theme.BLACK_COLOR};
  pointer-events: none;
`;

const StatMainContent = styled.div`
  font-size: 64px;
  font-weight: 700;
`;

const StatSecondaryContent = styled.div`
  font-size: 24px;
  font-weight: 700;
`;

const Section = styled.section`
  margin: 56px 0;
`;

const StatContainer = styled.div`
  color: ${theme.BLACK_COLOR};
  background-color: ${theme.MINT};
  text-align: center;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px #A4A4A440;
  font-size: 16px;
  font-family: ${theme.SECONDARY_FONT};
  width: 100%;
  min-width: 220px;
  padding: 0 16px;

  h2 {
    font-size: 18px;
    font-weight: 400;
  }

  p {
    padding: 0 16%;
    line-height: 20px;
  }
`;
