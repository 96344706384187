import { useCallback, useEffect, useRef, useState } from "react";

const isBrowser = () => typeof window !== "undefined";

export const useInView = (
  options: IntersectionObserverInit = {
    threshold: 1.0,
    rootMargin: "0px",
  },
) => {
  const [inView, setInView] = useState(false);
  const ref = useRef<HTMLElement | null>(null);

  const observer = isBrowser()
    ? new IntersectionObserver(entries => {
        const [entry] = entries;
        setInView(entry.isIntersecting);
      }, options)
    : null;

  useEffect(() => {
    if (!observer || ref.current === null || !window.IntersectionObserver) {
      return;
    }

    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [observer, options]);

  const disconnect = () => observer?.disconnect();
  // const disconnect = useCallback(() => {
  //   observer?.disconnect();
  // }, [observer]);

  return { inView, ref, disconnect };
};
