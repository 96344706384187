import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import theme from "../../../../util/theme";
import { AppLoadingButton, AppText, AppTitle } from "../../../ui";
import { laptopSize, mobileSize } from "../../../../util/variables";
import { useInView } from "../../../../hooks/useInView";
import { web_acq_prepayment_section_view } from "../../../../services/mixpanel/acquisition-events";
import { PriceDataT } from "../../../../util/types";
import SubscriptionTimeline from "./SubscriptionTimeline";
import DigitalWalletIcon from "../../../ui/DigitalWalletIcon";

export interface SegmentI {
  id: string;
  handleRedirect: (e: React.MouseEvent<HTMLButtonElement>) => void;
  loading: boolean;
  whiteVersion?: boolean;
  priceData: PriceDataT;
  isFamilyPlanTest?: boolean;
  isWalletVariant?: boolean;
}

export const FreeTrialSegment: React.FC<SegmentI> = ({
  id,
  handleRedirect,
  priceData,
  loading,
  whiteVersion, // used for the prepayment hero section
  isFamilyPlanTest,
  isWalletVariant,
}) => {
  // const { customGroup } = useOnboardingContext();
  const { inView, ref, disconnect } = useInView({
    threshold: 0.8,
  });

  useEffect(() => {
    if (inView) {
      web_acq_prepayment_section_view(whiteVersion ? "Hero" : "Free trial");
      disconnect();
    }

    return () => {
      disconnect();
    };
  }, [inView, disconnect, whiteVersion]);

  return (
    <Container ref={ref} id={id} whiteVersion={whiteVersion}>
      {whiteVersion && (
        <HeroTitle version2={false}>
          {/* {pageVersion2 ? "Experience a New Way to Learn" : "Try Imprint, free for 7 days"} */}
          Try Imprint, free for 7 days
        </HeroTitle>
      )}
      <Content>
        {!whiteVersion && <H2>Your Free Trial With Imprint</H2>}
        <>
          <SubscriptionTimeline />
          {/* <DiscountContainer>
            <DiscountTextWrapper>
              <SegmentText
                backgroundColor="#FC6145"
                color={theme.WHITE_COLOR}
                mobileSize={14}
                weight={500}
              >
                {isFamilyPlanTest ? `20% DISCOUNT UNLOCKED` : <>{customGroup}{customGroup && <br/>} DISCOUNT UNLOCKED!</>}
              </SegmentText>
            </DiscountTextWrapper>
            <SegmentText color={theme.RED}>50% off for New Year’s</SegmentText>
          </DiscountContainer> */}
          {isFamilyPlanTest ? null : (
            <PriceContainer>
              <AppText
                noMargin
                color={theme.RED}
                textAlign="center"
                style={{ textTransform: "uppercase", fontWeight: 800, marginBottom: 4 }}
              >
                DISCOUNT UNLOCKED!
              </AppText>
              <SegmentText color={theme.PRIMARY_COLOR}>
                <s>
                  {priceData.currency.symbol}
                  {priceData.monthly_price}
                </s>{" "}
                <b>
                  {priceData.currency.symbol}
                  {priceData.discounted_monthly_price} per month
                </b>{" "}
                (billed annually){" "}
              </SegmentText>
            </PriceContainer>
          )}
          <AppLoadingButton
            type="button"
            disabled={loading}
            loading={loading}
            style={{ margin: "12px auto 0 auto" }}
            data-cta-type="button"
            onClick={handleRedirect}
          >
            {isWalletVariant ? "Activate free trial" : "Start your 7-day free trial"}
          </AppLoadingButton>
          {isWalletVariant && (
            <button
              type="button"
              disabled={loading}
              style={{ display: "block", background: "none", border: "none", margin: "0 auto" }}
              data-cta-type="wallet_pay"
              onClick={handleRedirect}
            >
              <AppText color={theme.PRIMARY_COLOR} fontWeight={500} textAlign="center" style={{ marginTop: 16, marginBottom: 8 }}>
                In a rush? Activate with <DigitalWalletIcon />
              </AppText>
            </button>
          )}
        </>
        {/* )} */}
      </Content>
      {/* {whiteVersion && pageVersion2 && (
        <>
          <AppLoadingButton
            type="button"
            disabled={loading}
            loading={loading}
            style={{ margin: "24px auto 16px auto" }}
            onClick={handleRedirect}
          >
            Try For Free
          </AppLoadingButton>
          <SegmentText size={16} mobileSize={14} style={{ maxWidth: 327, margin: "0 auto" }}>
            <i>
              *Cancel anytime. Get your first year for 20% off, then $
              {highPriceVersion ? "124.99" : "99.99"} per year after.
            </i>
          </SegmentText>
        </>
      )} */}
    </Container >
  );
};

const Container = styled.section<{ whiteVersion?: boolean }>`
  background-color: ${props => (props.whiteVersion ? "#fafafa" : theme.PRIMARY_COLOR)};
  ${props =>
    !props.whiteVersion &&
    css`
      padding: 80px;
      @media ${laptopSize} {
        padding: 64px;
      }
      @media ${mobileSize} {
        padding: 64px 24px;
      }
    `}
`;

const Content = styled.div`
  max-width: 1200px;
  padding: 56px 88px;
  margin: 0 auto;
  border-radius: 16px;
  background-color: ${theme.WHITE_COLOR};
  box-shadow: 0px 2px 8px rgba(164, 164, 164, 0.25);
  font-family: ${theme.SECONDARY_FONT};

  @media ${laptopSize} {
    padding: 36px 56px;
  }

  @media ${mobileSize} {
    padding: 32px 16px 32px 24px;
  }
`;

const HeroTitle = styled(AppTitle as any)`
  text-align: center;
  margin-top: 30px;
  margin-bottom: 40px;
  @media ${mobileSize} {
    text-align: ${props => (props.version2 ? "center" : "left")};
    font-size: 40px;
    line-height: 50px;
    margin-top: 12px;
    margin-bottom: 28px;
  }
`;

const H2 = styled(AppTitle as any).attrs(() => ({
  as: "h2",
}))`
  text-align: center;
  margin-bottom: 60px;
  @media ${mobileSize} {
    margin-bottom: 40px;
  }
`;

interface SegmentTextProps {
  color?: string;
  size?: number;
  mobileSize?: number;
  weight?: number;
  backgroundColor?: string;
}

const SegmentText = styled.p<SegmentTextProps>`
  margin: 0;
  text-align: center;
  font-size: ${props => props.size ?? 18}px;
  font-family: ${theme.SECONDARY_FONT};
  line-height: ${props => (props.size ? props.size + 6 : 24)}px;
  background-color: ${props => props.backgroundColor ?? null};
  color: ${props => props.color ?? theme.BLACK_COLOR};
  ${props =>
    props.weight &&
    css`
      font-weight: ${props.weight};
    `};
  @media ${mobileSize} {
    font-size: ${props => props.mobileSize ?? 18}px;
    line-height: ${props => (props.mobileSize ? props.mobileSize + 6 : 24)}px;
  }
`;

const PriceContainer = styled.div`
  margin: 24px 0 12px 0;
  @media ${mobileSize} {
    margin: 12px 0;
  }
`;

const DiscountContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(252, 97, 69, 0.08);
  width: 100%;
  max-width: 395px;
  min-height: 64px;
  margin-bottom: 24px;
  border-radius: 8px;
  padding: 8px;
  margin: 0 auto 8px auto;
`;

const DiscountTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  background-color: #fc6145;
  border-radius: 8px;
  margin-bottom: 8px;
`;
