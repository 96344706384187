import * as React from "react";
import styled from "styled-components";
import { AppText, Wrapper, AppTitle, AppButton } from "../../ui";
import theme from "../../../util/theme";
import { mobileSize, laptopSize } from "../../../util/variables";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { handleGetStarted } from "./HeroSection";
import DeepImageMobile from "../../../content/img/homepage/deep-understanding-mobile.webp";

const DeepUnderstandingSegment: React.FC = React.memo(() => {
  const data = useStaticQuery(graphql`
    query PhImageQuery {
      desktop: file(relativePath: { eq: "homepage/deep-understanding-desktop.webp" }) {
        childImageSharp {
          fixed(quality: 80, height: 623, width: 1040) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `);

  return (
    <ContainerDiv id="more">
      <Wrapper>
        <ContentDiv>
          <AlignVerticallyDiv>
            <div>
              <MobileImageDiv>
                <img src={DeepImageMobile} alt="Imprint - Deep Understanding" loading="eager" />
              </MobileImageDiv>
              <WhiteAppTitle component="h2">Deep understanding.</WhiteAppTitle>
              <WhiteAppText>
                Imprint helps you understand complex topics quickly, with elegant visuals that
                clarify key ideas and help you stay focused.
              </WhiteAppText>
              <AppButton dark onClick={handleGetStarted}>
                Get Started
              </AppButton>
            </div>
          </AlignVerticallyDiv>
          <ImgDiv>
            <Img
              fixed={data.desktop.childImageSharp.fixed}
              style={{
                display: "inherit",
                minHeight: "100%",
                maxHeight: "100%",
                top: 0,
                bottom: 0,
              }}
              imgStyle={{
                objectFit: "stretch",
                objectPosition: "right",
              }}
              alt="Imprint - Deep Understanding"
              loading="eager"
            />
          </ImgDiv>
        </ContentDiv>
      </Wrapper>
    </ContainerDiv>
  );
});

const MobileImageDiv = styled.div`
  display: none;
  @media ${mobileSize} {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100vw;
    aspect-ratio: 457/650;
    margin-left: -30px;
    margin-bottom: 30px;
    z-index: -1;
  }
`;

const AlignVerticallyDiv = styled.div`
  max-height: 623px;
  min-height: 623px;
  display: flex;
  align-items: center;
  z-index: 5;
  @media ${mobileSize} {
    min-height: fit-content;
    max-height: fit-content;
  }
`;

const ImgDiv = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  min-height: 100%;
  @media ${laptopSize} {
    top: 0;
    bottom: 0;
    right: -20%;
  }
  @media ${mobileSize} {
    display: none;
  }
`;

const WhiteAppTitle = styled(AppTitle as any)`
  color: ${theme.WHITE_COLOR};
  @media ${laptopSize} {
    z-index: 4;
    max-width: 50vw;
  }
  @media ${mobileSize} {
    z-index: 1;
    max-width: 100%;
  }
`;

const WhiteAppText = styled(AppText as any)`
  color: ${theme.WHITE_COLOR};
  max-width: 475px;
  @media ${laptopSize} {
    z-index: 4;
    max-width: 50vw;
  }
  @media ${mobileSize} {
    z-index: 1;
    max-width: 100%;
  }
`;

const ContainerDiv = styled.div`
  background: ${theme.PRIMARY_COLOR};
  width: 100%;
  position: relative;
  min-height: 623px;
  max-height: 623px;
  /* z-index: -2; */
  @media ${mobileSize} {
    min-height: fit-content;
    max-height: fit-content;
    padding-bottom: 60px;
  }
`;

const ContentDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* @media ${mobileSize} {
    flex-direction: column-reverse;
  } */
`;

export { DeepUnderstandingSegment };
