import React from "react";
import styled from "styled-components";
import theme from "../../../util/theme";
import { mobileSize } from "../../../util/variables";
import { AppButton, AppText } from "../../ui";
import { FlexDiv } from "../../ui/FlexDiv";
import { Chart, ChartMobile } from "../../../content/assets/imprint/";
import { ContinueButton } from "./HappinessQuestions";

interface Props {
  quote: string;
  author: string;
  date: string;
  image: string;
  icon: string;
  handleNext: (answer: string | string[] | undefined) => void;
}

export const ChartBreak2: React.FC<Props> = ({ quote, author, date, image, icon, handleNext }) => {
  return (
    <Container>
      {/* <Banner>7 day personalized course</Banner> */}
      <ChartImg src={Chart} alt="Self-reported Happiness Level Over Time chart" />
      <ChartImgMobile src={ChartMobile} alt="Self-reported Happiness Level Over Time chart"/>
      <Bullets>
        <li>
          <b>Structure:</b> 7 20-minute daily sessions
        </li>
        <li>
          <b>Content:</b> 7 in-depth visual lessons on proven methods for increasing happiness from Harvard, Yale, and Columbia
        </li>
        <li>
          <b>Habits:</b> 13 guided exercises on habits that promote a sense of well-being and reduce negative emotions
        </li>
        <li>
          <b>Instructor:</b> 1 personal instructor who will hold you accountable for attending each daily session and completing the work. We won’t let you give up.
        </li>
      </Bullets>
      <Cost>
        <CostText>$49.99 one time payment</CostText>
        <FlexDiv direction="column" style={{ marginBottom: 24 }}>
          <MobileCostText>$49.99</MobileCostText>
          <MobileCostText>one time payment</MobileCostText>
        </FlexDiv>
        <AppText
          fontSize={16}
          mobileFontSize={14}
          color={theme.SLATE_COLOR_2}
          textAlign="center"
          style={{ margin: 0 }}
        >
          Money back guarantee: If you complete the entire course and don’t feel happier, we’ll give you a full refund.
        </AppText>
      </Cost>
      <FlexDiv direction="column" align="center">
        <AppText>{quote}</AppText>
        <FlexDiv>
          <Headshot src={icon} alt="headshot of person" width={48} height={48} />
          <AuthorInfo>
            <AppText fontSize={18} mobileFontSize={18} style={{ margin: 0 }}>
              {author}
            </AppText>
            <AppText fontSize={14} mobileFontSize={14} style={{ margin: 0 }}>
              {date}
            </AppText>
          </AuthorInfo>
        </FlexDiv>
      </FlexDiv>
      <ContinueButton onClick={() => handleNext(undefined)}>Continue</ContinueButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 100%;
  max-width: 586px;
  margin-top: 20px;
  padding-bottom: 40px;
  @media ${mobileSize} {
    padding-bottom: 64px;
  }
`;

const ChartImg = styled.img`
  position: relative;
  right: 28px;
  height: 348px;
  @media ${mobileSize} {
    display: none;
  }
`;

const ChartImgMobile = styled.img`
  display: none;
  @media ${mobileSize} {
    display: block;
    min-height: 190px;
    width: 100vw;
  }
`;

const Bullets = styled.ul`
  border-radius: 8px;
  padding: 32px 32px 32px 48px;
  font-size: 18px;
  background-color: rgba(255, 218, 110, 0.4);
  b {
    margin-right: 4px;
  }
`;

const Cost = styled.div`
  border-top: 2px solid ${theme.GREY_COLOR};
  border-bottom: 2px solid ${theme.GREY_COLOR};
  padding: 40px 56px;
  @media ${mobileSize} {
    padding: 40px 0;
  }
`;

const CostText = styled.p`
  margin-bottom: 4px;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  color: ${theme.YELLOW};
  @media ${mobileSize} {
    display: none;
  }
`;

const MobileCostText = styled.p`
  display: none;
  margin-bottom: 8px;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  color: ${theme.YELLOW};
  @media ${mobileSize} {
    display: block;
    /* margin: 0; */
  }
`;

const Headshot = styled.img`
  position: relative;
  top: -8px;
  width: 48px;
  height: 48px;
`;

const AuthorInfo = styled.div`
  position: relative;
  margin-left: 28px;
  ::before {
    content: "—";
    position: absolute;
    left: -18px;
    top: 0;
    width: 5px;
    height: 5px;
  }
`;
