import React from "react";
import { FlexDiv } from "../../ui";
import theme from "../../../util/theme";
import styled from "styled-components";
import { mobileSize } from "../../../util/variables";
import LeftLaurel from "../../../content/img/homepage/left-laurel.svg";
import AppleIcon from "../../../content/assets/icons/apple-logo-grey.png";
import GooglePlayIcon from "../../../content/assets/icons/google-play-logo-colored.png";
import ReviewsIcon from "../../../content/assets/icons/five-star-grey.svg";

enum ICON_TYPES {
  Apple = "Apple",
  Google = "Google Play",
  Reviews = "Reviews",
}

type AccoladeVariantT = "AppOfTheYear" | "EssentialEducationApp" | "AppOfTheDay" | "Over50kReviews";

interface AppOfTheYearAwardsProps {
  light?: boolean;
  accolades?: AccoladeVariantT[];
}

interface AwardProps {
  type: ICON_TYPES;
  text: React.ReactNode;
  light?: boolean;
  alt?: string;
}

export const AppOfTheYearAwards: React.FC<AppOfTheYearAwardsProps> = ({
  light,
  accolades = ["AppOfTheYear", "AppOfTheDay", "EssentialEducationApp"],
}) => {
  const getAwardContent = (variant: AccoladeVariantT) => {
    switch (variant) {
      case "AppOfTheYear":
        return {
          type: ICON_TYPES.Google,
          text: <AwardText>APP OF THE <br /> YEAR 2023</AwardText>,
          alt: "App of the year 2023",
        };
      case "AppOfTheDay":
        return {
          type: ICON_TYPES.Apple,
          text: <AwardText>APP OF THE <br /> DAY</AwardText>,
          alt: "App of the day",
        };
      case "EssentialEducationApp":
        return {
          type: ICON_TYPES.Apple,
          text: <AwardText>ESSENTIAL <br /> EDUCATION APP</AwardText>,
          alt: "Essential education app",
        };
      case "Over50kReviews":
        return {
          type: ICON_TYPES.Reviews,
          text: <AwardText>OVER 50K <br /> REVIEWS</AwardText>,
          alt: "Over 50k reviews",
        };
      default:
        return {
          type: ICON_TYPES.Google,
          text: <AwardText>APP OF THE <br /> YEAR 2023</AwardText>,
          alt: "App of the year 2023",
        };
    }
  };

  return (
    <AwardsContainer>
      {accolades.map((variant, index) => {
        const awardContent = getAwardContent(variant);
        return (
          <Award
            key={index}
            type={awardContent.type}
            light={light}
            text={awardContent.text}
            alt={awardContent.alt}
          />
        );
      })}
    </AwardsContainer>
  );
};

const Award: React.FC<AwardProps> = ({ type, text, light, alt }) => {
  const getIconSrc = (type: ICON_TYPES) => {
    switch (type) {
      case ICON_TYPES.Apple:
        return AppleIcon;
      case ICON_TYPES.Google:
        return GooglePlayIcon;
      case ICON_TYPES.Reviews:
        return ReviewsIcon;
      default:
        return GooglePlayIcon;
    }
  };

  const getIconWidth = (type: ICON_TYPES) => {
    switch (type) {
      case ICON_TYPES.Apple:
        return 23;
      case ICON_TYPES.Google:
        return 28;
      case ICON_TYPES.Reviews:
        return 34;
      default:
        return 28;
    }
  };

  return (
    <FlexDiv direction="column" align="center">
      <FlexDiv align="center" gap={type === ICON_TYPES.Reviews ? 0 : 4}>
        <Laurel src={LeftLaurel} light={light} alt="left laurel" height={42} width={17.5} />
        <FlexDiv direction="column" align="center">
          <FlexDiv justify="center" align="center" mobileAlign="flex-end" gap={8} mobileGap={4}>
            <PlatformImg
              src={getIconSrc(type)}
              width={getIconWidth(type)}
              // height={28}
              alt={alt}
              light={type === ICON_TYPES.Apple && light}
            />
          </FlexDiv>
        </FlexDiv>
        <Laurel
          src={LeftLaurel}
          light={light}
          alt="right laurel"
          height={42}
          width={17.5}
          style={{ transform: "scaleX(-1)" }}
        />
      </FlexDiv>
      {text}
    </FlexDiv>
  );
};

const AwardsContainer = styled.div`
  display: flex;
  gap: 40px;

  @media ${mobileSize} {
    width: 100%;
    gap: 24px;
    justify-content: center;
  }
`;

const PlatformImg = styled.img<{ light?: boolean }>`
  filter: ${props => (props.light ? "grayscale(100%) brightness(2.5)" : "")};
`;

const AwardText = styled.p`
  margin: 0;
  margin-top: 8px;

  font-size: 16px;
  font-family: ${theme.SECONDARY_FONT};
  font-weight: 500;
  line-height: 1.25;
  text-transform: uppercase;
  text-align: center;
  color: ${theme.BLACK_COLOR};
  @media ${mobileSize} {
    font-size: 14px;
  }
`;

const Laurel = styled.img<{ light?: boolean }>`
  filter: ${props => (props.light ? "brightness(1.05)" : "brightness(0.65)")};
  pointer-events: none;
`;
