import React from "react";
import { AppText, AppTitle } from "../../ui";
import theme from "../../../util/theme";
import styled, { css } from "styled-components";
import { mobileSize } from "../../../util/variables";
import Img1 from "../../../content/img/offer/iphone-1.webp";
import Img2 from "../../../content/img/offer/iphone-2.webp";
import Img3 from "../../../content/img/offer/iphone-3.webp";
// import Img1 from "../../../content/img/homepage/app-pitch-1.webp";
// import Img2 from "../../../content/img/homepage/app-pitch-2.webp";
// import Img3 from "../../../content/img/homepage/app-pitch-3.webp";
import { AppLink } from "../../ui/AppLink";
import { offer_landing_page_click } from "../../../services/mixpanel/discount-flow-events";
import { OfferSegmentI } from "../../../hooks/useOffer";

const PITCH_DATA = [
  {
    img: Img1,
    headline: "Learn something new in 2 minutes",
    text: "Fit bite-sized quick reads into your day, and retain what you learn.",
  },
  {
    img: Img2,
    headline: "Get in-depth insights in 15 minutes",
    text:
      "Not just summaries – Imprint has visual guides to popular books, courses, and exclusive content unique to Imprint.",
  },
  {
    img: Img3,
    headline: "Master complex topics with the world's greatest thinkers",
    text:
      "From philosophy to self-help, dive into exclusive visual lessons from top university professors, bestselling authors, and other experts.",
  },
];

const ValueSegment: React.FC<OfferSegmentI> = ({ priceData }) => {
  const { percentOff, coupon_id, trial, price_id } = priceData;
  return (
    <Container>
      <SectionsWrapper>
        {PITCH_DATA.map((item, i) => (
          <PitchSection key={item.headline} {...item} reverse={i % 2 === 1} />
        ))}
        <div style={{ padding: "0 24px" }}>
          <AppLink
            to="/subscription/register?from=email"
            margin="0 auto"
            state={{
              couponId: coupon_id,
              priceId: price_id,
              trial,
            }}
            onClick={() => offer_landing_page_click("value")}
          >
            {percentOff ? `Get ${percentOff}% Off Imprint` : "Start Imprint Free Trial"}
          </AppLink>
        </div>
      </SectionsWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 100px 72px;
  background: ${theme.WHITE_COLOR};

  @media ${mobileSize} {
    padding: 48px 0;
  }
`;

const SectionsWrapper = styled.div`
  max-width: 1280px;
`;

interface PitchSectionProps {
  img: string;
  headline: string;
  text: string;
  reverse?: boolean;
}

const PitchSection: React.FC<PitchSectionProps> = ({ img, headline, text, reverse = false }) => {
  return (
    <Section reverse={reverse}>
      <BlockWrapper reverse={reverse}>
        <StyledImg
          src={img}
          alt="2x2 grid of book images"
          width={186}
          height={403}
          loading="lazy"
        />
      </BlockWrapper>
      <div style={{ maxWidth: 554 }}>
        <H2 reverse={reverse}>{headline}</H2>
        <Text fontSize={24} reverse={reverse}>
          {text}
        </Text>
      </div>
    </Section>
  );
};

const H2 = styled(AppTitle as any).attrs(() => ({
  as: "h2",
}))`
  font-size: 36px;
  line-height: 40px;
  text-align: ${props => (props.reverse ? "right" : "left")};

  @media ${mobileSize} {
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    padding: 0 24px;
  }
`;

const Text = styled(AppText as any) <{ reverse: boolean }>`
  font-size: 24px;
  text-align: ${props => (props.reverse ? "right" : "left")};
  margin: 0;
  @media ${mobileSize} {
    font-size: 18px;
    text-align: center;
    padding: 0 24px;
  }
`;

const Section = styled.section<{ reverse: boolean }>`
  display: flex;
  flex-direction: ${props => (props.reverse ? "row-reverse" : "row")};
  align-items: center;
  gap: 100px;
  margin-bottom: 80px;

  @media ${mobileSize} {
    flex-direction: column;
    gap: 32px;
    margin-bottom: 56px;
  }
`;

const BlockWrapper = styled.div<{ reverse: boolean }>`
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    ${props =>
    props.reverse
      ? css`
            left: -56px;
          `
      : css`
            right: -56px;
          `};
    width: 50vw;
    height: 216px;
    background: #edeeef;
    border-radius: 20px;
    @media ${mobileSize} {
      width: 90vw;
      height: 184px;
    }
  }
`;

const StyledImg = styled.img`
  position: relative;
  min-width: 186px;
  width: 186px;
  height: 403px;

  @media ${mobileSize} {
    min-width: 158px;
    width: 158px;
    height: 342.5px;
  }
`;

export { ValueSegment };
