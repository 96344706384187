import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

const PrepaymentHeroImage = () => {
  const data = useStaticQuery(graphql`
    query KeyartQuery {
      desktop1: file(relativePath: { eq: "prepayment/keyart-display-desktop-1.webp" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      desktop2: file(relativePath: { eq: "prepayment/keyart-display-desktop-2.webp" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      mobile1: file(relativePath: { eq: "prepayment/keyart-display-mobile-1.webp" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      mobile2: file(relativePath: { eq: "prepayment/keyart-display-mobile-2.webp" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  const sourcesRow1 = [
    data.desktop1.childImageSharp.fluid,
    {
      ...data.mobile1.childImageSharp.fluid,
      media: `(max-width: 768px)`,
    },
  ];
  const sourcesRow2 = [
    data.desktop2.childImageSharp.fluid,
    {
      ...data.mobile2.childImageSharp.fluid,
      media: `(max-width: 768px)`,
    },
  ];

  return (
    <ImageContainer>
      <Img
        fluid={sourcesRow1}
        alt="keyart row 1"
        style={{ width: "100%", height: "100%" }}
      />
      <Img
        fluid={sourcesRow2}
        alt="keyart row 2"
        style={{ width: "100%", height: "100%" }}
      />
    </ImageContainer>
  );
};

export default PrepaymentHeroImage;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
`;
