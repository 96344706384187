const _roundTwoDecimals = (num: number) => Math.round((num + Number.EPSILON) * 100) / 100;
const _roundOneDecimal = (num: number) => Math.round((num + Number.EPSILON) * 10) / 10;
const _roundDownTwoDecimals = (value: number, decimalPlaces = 2) => {
  const multiplier = 10 ** decimalPlaces;
  const roundedValue = Math.floor(value * multiplier) / multiplier;
  return roundedValue;
};

export const _roundUpTwoDecimals = (value: number, decimalPlaces = 2) => {
  const multiplier = 10 ** decimalPlaces;
  const roundedValue = Math.ceil(value * multiplier) / multiplier;
  return roundedValue;
};


export const isNumeric = (str: string) => {
  return /^\d+$/.test(str);
};

export const calculateDiscountedPrice = (originalPrice: number, discountPercentage: number) => {
  if (discountPercentage < 0 || discountPercentage > 100) {
    throw new Error("Discount percentage must be between 0 and 100");
  }

  const discountAmount = (originalPrice * discountPercentage) / 100;
  const discountedPrice = originalPrice - discountAmount;

  return _roundDownTwoDecimals(discountedPrice).toFixed(2);
};
