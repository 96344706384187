import React from "react";
import styled from "styled-components";
import theme from "../../../util/theme";
import { mobileSize } from "../../../util/variables";
import { AppText } from "../../ui";
import { FlexDiv } from "../../ui/FlexDiv";
import { ContinueButton } from "./HappinessQuestions";

interface Props {
  quote: string;
  author: string;
  date: string;
  image: string;
  icon: string;
  handleNext: (answer: string | string[] | undefined) => void;
}

export const ChartBreak: React.FC<Props> = ({ quote, author, date, image, icon, handleNext }) => {
  return (
    <Container>
      <Banner>7 day personalized course</Banner>
      <Img src={image} alt="Self-reported Happiness Level Over Time chart" />
      <FlexDiv direction="column" align="center">
        <AppText>{quote}</AppText>
        <FlexDiv>
          <img src={icon} alt="headshot of person" width={48} height={48} />
          <AuthorInfo>
            <AppText fontSize={18} mobileFontSize={18} style={{ margin: 0 }}>
              {author}
            </AppText>
            <AppText fontSize={14} mobileFontSize={14} style={{ margin: 0 }}>
              {date}
            </AppText>
          </AuthorInfo>
        </FlexDiv>
      </FlexDiv>
      <ContinueButton onClick={() => handleNext(undefined)}>Continue</ContinueButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  width: 100%;
  max-width: 586px;
  padding-bottom: 40px;
`;

const Banner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 585px;
  height: 60px;
  padding: 16px 20px;
  border-radius: 32px;
  background-color: ${theme.YELLOW};
  
  font-size: 32px;
  font-weight: 700;
  color: ${theme.WHITE_COLOR};
  line-height: 40px;
  text-align: center;

  @media ${mobileSize} {
    max-width: 100%;
    height: auto;
  }
`;

const Img = styled.img`
  height: 348px;
  @media ${mobileSize} {
    min-height: 190px;
    width: 100vw;
  }
`;

const AuthorInfo = styled.div`
  position: relative;
  margin-left: 28px;
  ::before {
    content: "—";
    position: absolute;
    left: -18px;
    top: 0;
    width: 5px;
    height: 5px;
  }
`;
