import styled, { css } from "styled-components";
import theme from "../../util/theme";
import { mobileSize } from "../../util/variables";

interface Props {
  margin?: string;
  background?: string;
  color?: string;
  width?: number | string;
  disabled?: boolean;
}

export const AnchorLinkButton = styled.a<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => (width ? (typeof width === "string" ? width : `${width}px`) : "343px")};
  height: 52px;
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  padding: 1rem;
  border-radius: 32px;
  text-align: center;
  box-sizing: border-box;

  background: ${({ background }) => background ?? theme.PRIMARY_COLOR};
  color: ${({ color }) => color ?? "#fefefe"};
  font-family: ${theme.SECONDARY_FONT};
  font-size: 17px;

  cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
  transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
  @media ${mobileSize} {
    width: 100%;
  }
`;
