import { DEFAULT_CURRENCY_CODE } from "../util/variables";

declare global {
  interface Window {
    fbq?: (...args: any) => void;
    ga?: (...args: any) => void;
  }
}

export const fbq = (...args: any) => {
  if (window !== undefined && window.fbq) {
    window.fbq(...args);
  }
};

export const ga = (...args: any) => {
  if (window !== undefined && window.ga) {
    window.ga(...args);
  }
};

/**
 * Initializes Facebook Pixel with the given user ID and tracks a purchase event.
 * It is important to call this function for tracking purchases instead of calling `fbq` directly.
 * The eventID is necessary for deduplication of events.
 *
 * @param {Object} params - The parameters for the Facebook conversion event.
 * @param {string} [params.subscription_type] - The type of subscription.
 * @param {number} params.value - The monetary value of the event.
 * @param {string} params.uid - The unique identifier for the user.
 * @param {string} [params.currency] - The currency code in which the transaction is processed.
 */
export const sendFBConversionEvent = ({
  subscription_type,
  value,
  uid,
  currency,
}: {
  subscription_type?: string;
  value: number;
  uid: string;
  currency?: string;
}) => {
  // Hold on to the update flow;

  if (uid) {
    const currentDomain = typeof window === "undefined" ? "" : window.location.hostname;
    fbq("init", currentDomain === "learnwithimprint.com" ? "1656064025121854" : "218535230261838", {
      external_id: uid,
    });

    fbq(
      "track",
      "Purchase",
      {
        currency: currency ?? DEFAULT_CURRENCY_CODE,
        value,
        ...(subscription_type ? {
          content_type: "product",
          contents: [{ id: `${subscription_type}_product`, quantity: 1 }],
        } : {}),
      },
      { eventID: `event.purchase.${uid}` },
    );
  }
};

export const sendFBAddToCartEvent = ({
  subscription_type,
  value,
}: {
  subscription_type: string;
  value: number;
}) => {
  // Hold on to the update flow;

  fbq("track", "AddToCart", {
    currency: "USD",
    value: value,
    content_type: "product",
    contents: [{ id: `${subscription_type}_product`, quantity: 1 }],
  });
};

export const sendHappinessFBPurchaseEvent = async () => {
  fbq("track", "CompleteRegistration", {
    currency: "USD",
    value: 150,
    content_name: "Happiness_Course",
  });
};

export const sendGAConversionEvent = (...args: any) => {
  if (window !== undefined && window.ga) {
    window.ga(...args);
  }
};
