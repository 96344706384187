import { Router } from "@reach/router";
import * as React from "react";
import styled from "styled-components";
import {
  ConfirmAccountForm,
  Profile,
  ResetPassword,
  UpdateEmailForm,
  UpdatePasswordForm,
  UserForm,
} from "../components/PageSegments/user";
import PrivateRoute from "../components/privateRoute";
import PublicRoute from "../components/publicRoute";
import { NavBar } from "../components/ui";
import { BasicLayout } from "../layouts/BasicLayout";
import theme from "../util/theme";
import { LoginPage } from "../components/PageSegments/user/LoginPage";

const UserRoute = (data: any) => {
  return (
    <BasicLayout>
      <Main>
        <NavBar initiallyWhite position="fixed" />
        <Router css={{ width: "100%" }}>
          <PublicRoute component={LoginPage} path="/user/login" type="Login" />
          <PublicRoute component={UserForm} path="/user/login/email" />
          <PublicRoute component={ResetPassword} path="/user/resetPassword" />
          <PrivateRoute path="/user/profile" component={Profile} />
          <PrivateRoute path="/user/confirm-account" component={ConfirmAccountForm} />
          <PrivateRoute path="/user/updateEmail" component={UpdateEmailForm} />
          <PrivateRoute path="/user/updatePassword" component={UpdatePasswordForm} />
        </Router>
      </Main>
    </BasicLayout>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: ${theme.GREY_COLOR};
`;

export default UserRoute;
