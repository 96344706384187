import React, { useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img, { FixedObject } from "gatsby-image";
import styled from "styled-components";
import theme from "../../../../util/theme";
import { mobileSize } from "../../../../util/variables";
import { AppText, AppTitle } from "../../../ui";
import { AnchorLinkButton } from "../../../ui/AnchorLinkButton";
import { useInView } from "../../../../hooks/useInView";
import { web_acq_prepayment_section_view } from "../../../../services/mixpanel/acquisition-events";

export const WhatsInsideSegment = ({ scrollId }: { scrollId: string }) => {
  const { inView, ref, disconnect } = useInView({
    threshold: 0.4,
  });

  useEffect(() => {
    if (inView) {
      web_acq_prepayment_section_view("Whats Inside");
      disconnect();
    }

    return () => {
      disconnect();
    };
  }, [inView, disconnect]);

  const data = useStaticQuery(graphql`
    query WhatsInsideQuery {
      desktop1: file(relativePath: { eq: "prepayment/whats-inside-1.png" }) {
        childImageSharp {
          fixed(quality: 100, height: 285, width: 285) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      desktop2: file(relativePath: { eq: "prepayment/whats-inside-2.png" }) {
        childImageSharp {
          fixed(quality: 100, height: 285, width: 285) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      desktop3: file(relativePath: { eq: "prepayment/whats-inside-3.png" }) {
        childImageSharp {
          fixed(quality: 100, height: 285, width: 285) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `);

  const SECTION_DATA = [
    {
      img: data.desktop1.childImageSharp.fixed,
      headline: "In-depth learning",
      text: "A completely new learning experience with elegant visuals and expert knowledge.",
    },
    {
      img: data.desktop2.childImageSharp.fixed,
      headline: "Designed to help you focus",
      text: "Lessons are visual and tappable, to hold your attention.",
    },
    {
      img: data.desktop3.childImageSharp.fixed,
      headline: "Remember what you’ve learned",
      text: "Quizzes and collectible cards help you commit key concepts to long-term memory.",
    },
  ];

  return (
    <Container ref={ref}>
      <AppTitle component="h2">What’s Inside</AppTitle>
      <div>
        {SECTION_DATA.map((item, i) => (
          <InsideSection key={item.headline} {...item} reverse={i % 2 === 1} />
        ))}
      </div>
      <AnchorLinkButton href={"#" + scrollId}>Get Imprint</AnchorLinkButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 80px;

  padding: 120px 72px;
  background: ${theme.WHITE_COLOR};

  @media ${mobileSize} {
    padding: 64px 24px;
    gap: 32px;
  }
`;

interface InsideSectionProps {
  img: FixedObject;
  headline: string;
  text: string;
  reverse?: boolean;
}

const InsideSection: React.FC<InsideSectionProps> = ({ img, headline, text, reverse = false }) => {
  return (
    <Section reverse={reverse}>
      <ImgContainer>
        <Img fixed={img} alt="2x2 grid of book images" />
      </ImgContainer>
      <div>
        <H2 reverse={reverse}>{headline}</H2>
        <Text fontSize={24} reverse={reverse}>
          {text}
        </Text>
      </div>
    </Section>
  );
};

const H2 = styled(AppTitle as any).attrs(() => ({
  as: "h2",
}))`
  font-size: 36px;
  line-height: 40px;
  text-align: ${props => (props.reverse ? "right" : "left")};
  @media ${mobileSize} {
    font-size: 24px;
    line-height: 28px;
    text-align: center;
  }
`;

const Text = styled(AppText as any)<{ reverse: boolean }>`
  font-size: 24px;
  max-width: 554px;
  text-align: ${props => (props.reverse ? "right" : "left")};
  margin: 0;
  @media ${mobileSize} {
    font-size: 18px;
    text-align: center;
  }
`;

const Section = styled.section<{ reverse: boolean }>`
  display: flex;
  flex-direction: ${props => (props.reverse ? "row-reverse" : "row")};
  align-items: center;
  gap: 56px;
  max-width: 1000px;
  margin-bottom: 24px;

  @media ${mobileSize} {
    flex-direction: column;
    gap: 32px;
    margin-bottom: 56px;
  }
`;

const ImgContainer = styled.div`
  height: 285px;
  width: 285px;
`;
