import styled, { css } from "styled-components";
import theme from "../../util/theme";
import { laptopSize } from "../../util/variables";

interface Props {
  margin?: string;
  maxWidth?: number;
  fontSize?: number;
  fontWeight?: number;
  color?: string;
  textAlign?: string;
  mobileFontSize?: number;
  component?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
}

export const Title = styled.h1<Props>`
  margin: ${props => (props.margin ? props.margin : "0 0 0.5em 0")};
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : "auto")};
  text-align: ${props => (props.textAlign ? props.textAlign : "left")};
  font-family: ${theme.PRIMARY_FONT};
  font-weight: ${props => props.fontWeight ?? 400};
  font-size: ${props => (props.fontSize ? props.fontSize : 56)}px;
  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `};
  @media ${laptopSize} {
    font-size: ${props => (props.mobileFontSize ? props.mobileFontSize : 35)}px;
    line-height: ${props => (props.mobileFontSize ? props.mobileFontSize + 6 : 40)}px;
  }
`;

export const AppTitle = styled(Title).attrs<Props>(props => ({
  as: props.component ?? "h1",
}))<Props>``;
