import React, { useEffect } from "react";
import { BasicLayout } from "../layouts/BasicLayout";
import { useOnboardingContext } from "../components/PageSegments/onboarding/OnboardingContext";
import { web_acq_landing_page_view } from "../services/mixpanel/acquisition-events";
import { fbq } from "../services/pixel";
import { useABTest } from "../hooks/useABTest";
import SimplifiedLandingPage from "../ab-tests/SimplifiedLandingPage";
export const WEB_SKILLS_ONBOARDING_TEST_NAME = "202501_WebSkillsOnboarding";
export const ONBOARDING_VARIANT_DATA = {
  testName: WEB_SKILLS_ONBOARDING_TEST_NAME,
  variants: { control: "control", variant: "variant" },
  weights: { control: 0.5, variant: 0.5 },
};

// flow for purchasing with trial. Used for Facebook traffic
export default (data: any) => {
  const { setOnboardingLandingPage } = useOnboardingContext();
  const variant = useABTest(ONBOARDING_VARIANT_DATA);
  const urlParams = new URLSearchParams(data.location.search);
  const fromParam = urlParams.get("from") ?? undefined;

  useEffect(() => {
    web_acq_landing_page_view();
    fbq("trackCustom", "landing_page_view");
    setOnboardingLandingPage("web_discount_flow");
  }, [setOnboardingLandingPage, fromParam]);

  useEffect(() => {
    if (variant === "control") {
      localStorage.setItem(WEB_SKILLS_ONBOARDING_TEST_NAME, "simplified");
    }
  }, [variant]);

  return (
    <BasicLayout>
      <SimplifiedLandingPage />
    </BasicLayout >
  );
};
