import React from "react";
import { LibrarySegment } from "../../special-offer/prepayment";
import styled from "styled-components";
import theme from "../../../../util/theme";
import { AppLoadingButton, Wrapper } from "../../../ui";
import { FlexDiv } from "../../../ui";
import { mobileSize, PRIVACY_POLICY_PATH, TERMS_OF_SERVICE_PATH } from "../../../../util/variables";
import { OfferGoSegmentI } from "../../../../hooks/useOffer";
import { calculateDiscountedPrice } from "../../../../util/numbers";
import AuthStatusMessage from "./AuthStatusMessage";
import { Link } from "gatsby";

export const LibrarySection: React.FC<OfferGoSegmentI> = ({ priceData, buttonClicked, handleClick }) => {
  const { percentOff, annual_price, currency_symbol } = priceData;
  const discountedPrice = calculateDiscountedPrice(Number(annual_price), percentOff ?? 0);

  return (
    <>
      <LibrarySegment />
      <LibraryFooter>
        <Wrapper>
          <FooterContentContainer>
            <FlexDiv direction="column" align="center" gap={20}>
              <FlexDiv direction="column" align="center">
                <AppLoadingButton
                  dark
                  type="button"
                  loading={buttonClicked}
                  disabled={false}
                  style={{ fontSize: 18 }}
                  onClick={() => handleClick("library")}
                >
                  {`Get ${percentOff}% Off`}
                </AppLoadingButton>
                <AuthStatusMessage priceData={priceData} color={theme.WHITE_COLOR} />
              </FlexDiv>
              <Terms>
                By continuing, you agree to Imprint&apos;s{" "}
                <Link to={TERMS_OF_SERVICE_PATH}>
                  Terms of Use
                </Link>{" "}
                and{" "}
                <Link to={PRIVACY_POLICY_PATH}>
                  Privacy Policy
                </Link>.
                This offer is valid for a limited time only for Imprint premium access. Starts at{" "}
                {
                  percentOff ?
                    `${currency_symbol}${discountedPrice} for the first year and 
                    then annually at ${currency_symbol}${annual_price} after that.` :
                    `${currency_symbol}${annual_price} each year.`
                } Cancel anytime in your account settings at least 24 hours in
                advance. This offer is only available for subscription purchases
                made via our website.
              </Terms>
            </FlexDiv>
          </FooterContentContainer>
        </Wrapper>
      </LibraryFooter>
    </>
  );
};

const LibraryFooter = styled.div`
  background-color: ${theme.PRIMARY_COLOR};
  padding: 20px 0 40px 0;
`;

const FooterContentContainer = styled.div`
  max-width: 640px;
  margin: auto;
  padding: 20px 0;
  @media ${mobileSize} {
    padding: 0;
  }
`;

const Terms = styled.p`
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  font-family: ${theme.SECONDARY_FONT};
  color: ${theme.WHITE_COLOR};
  text-align: center;
  margin: 0;
  a {
    color: ${theme.WHITE_COLOR};
  }
`;
