import styled from "styled-components";
import * as React from "react";
import { BottomNavSegment } from "../components/PageSegments/BottomNav";
import { HowImprintWorks } from "../components/PageSegments/why-trust-imprint/HowImprintWorks";
import { ImprintProcess } from "../components/PageSegments/why-trust-imprint/ImprintProcess";
import { BasicLayout } from "../layouts/BasicLayout";

export default () => {
  return (
    <BasicLayout noFollow>
      <Main as="main">
        <HowImprintWorks />
        <ImprintProcess />
        <BottomNavSegment />
      </Main>
    </BasicLayout>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
