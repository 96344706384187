import { useEffect, useState } from "react";
import { auth } from "../services/firebase";
import { User } from "firebase/auth";

export function useAuth() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    if (typeof window === "undefined" || !auth) return;

    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setUser(user);
        console.debug("Debug: User is signed in.", user);
      } else {
        setUser(null);
        console.debug("Debug: No user is signed in.");
      }

      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return { user, loading };
}
