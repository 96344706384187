import Download from "./download.png";
import Icon from "./Icon.png";
import Loading from "./loading.png";
import Download_White_Svg from "./download_white.svg";
import DesktopBackground from "./desktop_background.jpg";
import Pitch_Logo from "./pitch_logo.png";
import End_Of_Chapter_Background from "./end_of_chapter_background.png";
import Nutrition from "./Nutrition.png";
import Macroeconomics from "./Macroeconomics.png";
import Meditation from "./Meditation.png";
import Negotiation from "./Negotiation.png";
import Productivity from "./Productivity.png";
import Existentialism from "./Existentialism.png";
// import History from "./History Icon.png";
// import Philosophy from "./Philosophy icon.png";
// import Business from "./Business icon.png";
// import Leadership from "./Leadership icon.png";
// import Psychology from "./Psychology icon.png";
import History from "./history-icon.svg";
import Philosophy from "./philosophy-icon.svg";
import Business from "./business-icon.svg";
import Leadership from "./leadership-icon.svg";
import Psychology from "./psychology-icon.svg";
import Politics from "./politics-icon.svg";
import ProductivityIcon from "./productivity-icon.svg";
import Art from "./art-icon.svg";
import Design from "./design-icon.svg";
import Economics from "./economics-icon.svg";
import Wordmark from "./Wordmark.svg";
import OnboardingName from "./210712-Onboarding-About-Me-CI-v002.json";
import OnboardingComplete from "./210715-Lucid-Onboarding-Anim-CI-v007.json";
import Onboarding1 from "./Onboarding-Slide-02.json";
import Onboarding2 from "./Onboarding-Slide-03.json";
import Onboarding3 from "./Onboarding-Slide-06.json";
import Onboarding4 from "./Onboarding-Slide-20-10.json";
import OnboardingNew from "./220225-Onboard-3Color-Slide-1-5-Cropped.json";
import OnboardingApp1 from "./Onboarding-Slide-07-20.json";
import OnboardingApp2 from "./Onboarding-Slide-14-6.json";
import OnboardingApp3 from "./Onboarding-Slide-15-3.json";
import OnboardingApp4 from "./Onboarding-Slide-16-3.json";
import OnboardingApp5 from "./Onboarding-Slide-17-3.json";
import OnboardingSkills from "./240304-Onboarding-Small-Goals-2-2.json";
import Quote1 from "./Quote 1 - Mobile.png";
import Quote2 from "./Quote 2 - Mobile.png";
import Quote3 from "./Quote 3 - Mobile.png";
import Quote4 from "./Quote 4 - Mobile.png";
import SubscriptionDots from "./subscribeDots.svg";
import logo from "./logo.png";
import one from "./one.png";
import two from "./two.png";
import three from "./three.png";
import DesktopLogo from "./DesktopLogo.png";
import Stars from "./Stars.svg";
import AppleStoreIcon from "./AppleStoreIcon.svg";
import QuotationMark from "./quotation-mark.svg";
import AppStoreBadge from "./app-store-badge.png";
import GooglePlayBadge from "./google-play-badge.png";
import AppStoreBadgeWhite from "./app-store-badge-white.svg";
import PrePaymentBell from "./PrePaymentBell.json";

export {
  Download,
  Icon,
  Loading,
  Download_White_Svg,
  DesktopBackground,
  Pitch_Logo,
  End_Of_Chapter_Background,
  Meditation,
  Macroeconomics,
  Existentialism,
  Negotiation,
  Nutrition,
  Productivity,
  History,
  Philosophy,
  Business,
  Leadership,
  Psychology,
  Politics,
  ProductivityIcon,
  Art,
  Design,
  Economics,
  OnboardingName,
  OnboardingComplete,
  Onboarding1,
  Onboarding2,
  Onboarding3,
  Onboarding4,
  OnboardingNew,
  OnboardingApp1,
  OnboardingApp2,
  OnboardingApp3,
  OnboardingApp4,
  OnboardingApp5,
  OnboardingSkills,
  SubscriptionDots,
  logo,
  Wordmark,
  Quote1,
  Quote2,
  Quote3,
  Quote4,
  one,
  two,
  three,
  DesktopLogo,
  Stars,
  AppleStoreIcon,
  QuotationMark,
  AppStoreBadge,
  AppStoreBadgeWhite,
  GooglePlayBadge,
  PrePaymentBell,
};
