import * as React from "react";
import styled from "styled-components";
import { AppText, AppTitle, FlexDiv } from "../../ui";
import theme from "../../../util/theme";
import { mobileSize } from "../../../util/variables";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { AppLink } from "../../ui/AppLink";
import { OfferSegmentI } from "../../../hooks/useOffer";
import { offer_landing_page_click } from "../../../services/mixpanel/discount-flow-events";

const StatsSegment: React.FC<OfferSegmentI> = ({ priceData }) => {
  const { percentOff, coupon_id, trial, price_id } = priceData;
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "offer/universe.webp" }) {
        childImageSharp {
          fixed(quality: 100, height: 356, width: 378) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `);

  return (
    <ContainerDiv>
      <ContentDiv>
        <FlexDiv direction="column">
          <AppTitle component="h2" style={{ margin: 0 }}>96%</AppTitle>
          <AppText fontSize={32} mobileFontSize={20}>
            of regular users say Imprint makes learning easy
          </AppText>
          <AppText fontSize={20}>
            *BASED ON A SURVEY OF USERS WHO USED IMPRINT 3 TIMES IN A WEEK
          </AppText>
          <AppLink
            dark
            to="/subscription/register?from=email"
            state={{
              couponId: coupon_id,
              priceId: price_id,
              trial,
            }}
            onClick={() => offer_landing_page_click("stats")}
          >
            {/* TODO - change if trial needs to be taken into account */}
            {percentOff ? `Get ${percentOff}% Off Imprint` : "Start Imprint Free Trial"}
          </AppLink>
        </FlexDiv>
        <Img fixed={data.file.childImageSharp.fixed} />
      </ContentDiv>
    </ContainerDiv>
  );
};

const ContainerDiv = styled.div`
  background: ${theme.PRIMARY_COLOR};
  color: ${theme.WHITE_COLOR};
  width: 100%;
  padding: 69px;
  @media ${mobileSize} {
    padding: 48px 24px;
  }
`;

const ContentDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  @media ${mobileSize} {
    flex-direction: column-reverse;
  }
`;

export { StatsSegment };
