import React, { useEffect } from "react";
import SimplifiedLandingPage from "../ab-tests/SimplifiedLandingPage"
import { BasicLayout } from "../layouts/BasicLayout";
import { useOnboardingContext } from "../components/PageSegments/onboarding/OnboardingContext";
import { web_acq_landing_page_view } from "../services/mixpanel/acquisition-events";
import { fbq } from "../services/pixel";
import { useABTest } from "../hooks/useABTest";
import { ONBOARDING_VARIANT_DATA } from "./special-welcome";

// used for Facebook traffic
export default () => {
  const { setVariant, setOnboardingLandingPage } = useOnboardingContext();
  useABTest(ONBOARDING_VARIANT_DATA);

  useEffect(() => {
    web_acq_landing_page_view();
    fbq("trackCustom", "landing_page_view");
    setOnboardingLandingPage("web_discount_flow");
    setVariant("variant");
  }, [setVariant, setOnboardingLandingPage]);

  return (
    <BasicLayout>
      <SimplifiedLandingPage />
    </BasicLayout>
  );
};
