import { useEffect } from "react";
import { globalHistory } from "@reach/router";

export const useRouteWatcher = (callback: (location: Location) => void) => {
  useEffect(() => {
    const unlisten = globalHistory.listen(({ location }) => {
      // console.log("DEBUG route change");
      callback(location);
    });

    // Call callback with initial location
    callback(globalHistory.location);

    return () => {
      unlisten();
    };
  }, [callback]);
};
