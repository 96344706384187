import React, { createContext, useState, useEffect, useContext, useCallback } from "react";
import { PriceDataT } from "../util/types";
import useRemoteConfig from "../hooks/useRemoteConfig";
import { REMOTE_CONFIG_STRING_KEYS } from "../services/firebase-remote-config";
import { setMixpanelProperties } from "../services/mixpanel/mixpanel";
import { GeolocationI, getGeolocation } from "../services/ipgeolocation";
import {
  US_PRICE_WEB,
  getPriceDataFromLocation,
} from "../util/static-price-data";
import { sendSlackErrorMessage } from "../services/slack";
import { getUserEmail, getUserUID } from "../services/firebase";
import { COUPON_DISCOUNT_DATA, COUPON_IDS } from "../util/variables";
import { _roundUpTwoDecimals } from "../util/numbers";
import { useRouteWatcher } from "../hooks/useRouteWatcher";
import { CHECKOUT_URL_KEY } from "../pages/special-offer";

const DEFAULT_DISCOUNT_PERCENT = 20;
const LOCAL_STORAGE_KEY = "specialDiscount";
// links ending in / are for production, links without / are for development
const DEFAULT_DISCOUNT_LANDING_PAGES = [
  "/",
  "/gift/redeem/",
  "/gift/redeem",
  "/welcome/",
  "/welcome",
  "/special-welcome/",
  "/special-welcome",
  "/join/",
  "/join",
  "/offer/",
  "/offer",
  "/offer/go/",
  "/offer/go",
  "/joinnow/",
  "/joinnow",
  "/start/",
  "/start",
];
export type SpecialDiscount = (typeof COUPON_IDS)[keyof typeof COUPON_IDS] | null;

type LocalPriceContextType = {
  currentPrice: PriceDataT;
  location: GeolocationI | null;
  specialDiscount: SpecialDiscount;
  setSpecialDiscount: (specialDiscount: SpecialDiscount) => void;
  getDiscountPercent: () => number;
};

const defaultLocalPriceContext: LocalPriceContextType = {
  currentPrice: US_PRICE_WEB,
  location: null,
  specialDiscount: null,
  setSpecialDiscount: () => null,
  getDiscountPercent: () => DEFAULT_DISCOUNT_PERCENT,
};

export const LocalPriceContext = createContext<LocalPriceContextType>(defaultLocalPriceContext);

export const useLocalPriceContext = () => {
  const res = useContext(LocalPriceContext);
  if (!res) {
    console.error("local price context is used outside of provider");
  }

  return res;
};

export const LocalPriceProvider = ({
  children,
  specialDiscount: initialSpecialDiscount = null,
}: any) => {
  const [variant, useDefault] = useRemoteConfig(REMOTE_CONFIG_STRING_KEYS.stripePriceTest);
  const [location, setLocation] = useState<GeolocationI | null>(defaultLocalPriceContext.location);

  const [specialDiscount, setSpecialDiscount] = useState(() => {
    const savedDiscount = localStorage.getItem(LOCAL_STORAGE_KEY);
    return savedDiscount ?? initialSpecialDiscount;
  });

  const handleSetSpecialDiscount = (discount: SpecialDiscount) => {
    if (discount) {
      localStorage.setItem(LOCAL_STORAGE_KEY, discount);
    } else {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
    }

    setSpecialDiscount(discount);
  };

  const [currentPrice, setCurrentPrice] = useState<PriceDataT>(
    defaultLocalPriceContext.currentPrice,
  );

  const getDiscountPercent = () => {
    if (!specialDiscount) {
      return DEFAULT_DISCOUNT_PERCENT;
    }

    return COUPON_DISCOUNT_DATA.find(discount => discount.id === specialDiscount)?.discount ?? DEFAULT_DISCOUNT_PERCENT;
  };

  // Set localized price if can fetch user's location.
  useEffect(() => {
    const setLocalizedPrice = async () => {
      if (useDefault) {
        return;
      }

      // const inTest = variant === "test";
      // temp pause test for embeddables
      const inTest = false;
      const mixpanelPriceTest = {
        "202407WebPriceTest_2": inTest ? "Test162Price" : "Control125Price",
      };
      try {
        const userLocation = await getGeolocation();
        setLocation(userLocation);

        const priceData = { ...getPriceDataFromLocation(userLocation, inTest) };
        if (specialDiscount) {
          const discount = COUPON_DISCOUNT_DATA.find(discount => discount.id === specialDiscount);
          if (discount) {
            priceData.discounted_annual_price = _roundUpTwoDecimals((
              parseFloat(priceData.annual_price) * (1 - (discount.discount / 100))
            )).toFixed(2);
            priceData.discounted_monthly_price = _roundUpTwoDecimals((
              parseFloat(priceData.monthly_price) * (1 - (discount.discount / 100))
            )).toFixed(2);
          }
        }

        setCurrentPrice(priceData);

        setMixpanelProperties(
          {
            ...(userLocation.is_eu ? {} : { ...mixpanelPriceTest }),
            "Country Code": userLocation.country_code2,
          },
          true,
        );
      } catch (err) {
        setMixpanelProperties(mixpanelPriceTest, true);
        console.error(err);
        await sendSlackErrorMessage(
          `IP Geolocation API error. ${getUserEmail() ?? ""} ${getUserUID() ?? ""} ${err}`,
        );
      }
    };

    if (variant || specialDiscount) {
      setLocalizedPrice();
    }
  }, [variant, specialDiscount, useDefault]);

  // Handle special discount on landing pages
  const handleRouteChange = useCallback((location: Location) => {
    console.log("DEBUG route change", location);
    if (DEFAULT_DISCOUNT_LANDING_PAGES.includes(location.pathname)) {
      handleSetSpecialDiscount(null);
      sessionStorage.clear();
    }

    if (location.pathname === "/startnow" || location.pathname === "/startnow/") {
      handleSetSpecialDiscount(COUPON_IDS.percent50);
      sessionStorage.removeItem(CHECKOUT_URL_KEY);
    }
  }, []);

  useRouteWatcher(handleRouteChange);

  return (
    <LocalPriceContext.Provider
      value={{
        currentPrice,
        location,
        specialDiscount,
        setSpecialDiscount: handleSetSpecialDiscount,
        getDiscountPercent
      }}
    >
      {children}
    </LocalPriceContext.Provider>
  );
};
