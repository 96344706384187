import React from "react";
import styled, { css } from "styled-components";
import imprintLogo from "../../content/img/icon/imprint-logotype.png";
import { mobileSize } from "../../util/variables";

type LogoSizeT = "x-small" | "small" | "medium" | "large" | "x-large";

interface LogoProps {
  size?: LogoSizeT;
  mobileSize?: LogoSizeT;
  width?: number;
  height?: number;
  alt?: string;
  white?: boolean;
  style?: React.CSSProperties;
}

export const AppLogo: React.FC<LogoProps> = ({ size, mobileSize, width, height, alt, white, ...rest }) => {
  const getWidth = (size?: LogoSizeT) => {
    switch (size) {
      case "x-small":
        return "76px";
      case "small":
        return "96px";
      case "medium":
        return "112px";
      case "large":
        return "127px";
      case "x-large":
        return "150px";
      default:
        return `${width ?? 127}px`;
    }
  };

  return (
    <Img
      src={imprintLogo}
      alt={alt ?? "Imprint logo"}
      width={getWidth(size)}
      white={white ?? false}
      $mobileWidth={getWidth(mobileSize)}
      {...rest}
    />
  );
};

const Img = styled.img<{ white: boolean; $mobileWidth: string }>`
  width: ${props => props.width};

  ${props => props.$mobileWidth && css`
    @media (max-width: ${mobileSize}) {
      width: ${props.$mobileWidth};
    }
  `}

  ${props =>
    props.white &&
    css`
      filter: invert(1);
    `}
`;
