import React from "react";
import { AppText, AppTitle, Wrapper } from "../../../ui";
import theme from "../../../../util/theme";
import styled from "styled-components";
import { mobileSize } from "../../../../util/variables";
import Img1 from "../../../../content/img/homepage/app-pitch-1.webp";
import Img2 from "../../../../content/img/homepage/app-pitch-2.webp";
import Img3 from "../../../../content/img/homepage/app-pitch-3.webp";
import { AppLink } from "../../../ui/AppLink";
import { web_acq_landing_page_click } from "../../../../services/mixpanel/acquisition-events";

const PITCH_DATA = [
  {
    img: Img1,
    headline: "Learn something new in 2 minutes",
    text: "Fit bite-sized quick reads into your day, and retain what you learn.",
    reverse: false,
  },
  {
    img: Img2,
    headline: "Comprehensive insights",
    text:
      "Not just summaries – Imprint has visual guides to popular books, courses, and exclusive content unique to Imprint.",
    reverse: false,
  },
  {
    img: Img3,
    headline: "Master complex topics with the world's greatest thinkers",
    text:
      "From philosophy to self-help, dive into exclusive visual lessons from top university professors, bestselling authors, and other experts.",
    reverse: false,
  },
];

const AppPitchSegment = () => {
  return (
    <Container>
      <Wrapper>
        {PITCH_DATA.map((item, i) => (
          <PitchSection key={item.headline} {...item} reverse={i % 2 === 1} />
        ))}
        <AppLink
          to="/onboarding/questions"
          margin="0 auto"
          onClick={() => web_acq_landing_page_click("about the app")}
        >
          Get Started
        </AppLink>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 120px 72px;
  background: ${theme.WHITE_COLOR};

  @media ${mobileSize} {
    padding: 40px 0 102px 0;
    /* padding: 40px 72px 100px 72px; */
  }
`;

interface PitchSectionProps {
  img: string;
  headline: string;
  text: string;
  reverse?: boolean;
}

const PitchSection: React.FC<PitchSectionProps> = ({ img, headline, text, reverse = false }) => {
  return (
    <Section reverse={reverse}>
      <StyledImg src={img} alt="2x2 grid of book images" width={285} height={285} loading="lazy" />
      <div style={{ maxWidth: 554 }}>
        <H2 reverse={reverse}>{headline}</H2>
        <Text fontSize={24} reverse={reverse}>
          {text}
        </Text>
      </div>
    </Section>
  );
};

const H2 = styled(AppTitle as any).attrs(() => ({
  as: "h2",
}))`
  font-size: 36px;
  line-height: 40px;
  text-align: ${props => (props.reverse ? "right" : "left")};
  @media ${mobileSize} {
    font-size: 24px;
    line-height: 28px;
    text-align: center;
  }
`;

const Text = styled(AppText as any)<{ reverse: boolean }>`
  font-size: 24px;
  text-align: ${props => (props.reverse ? "right" : "left")};
  margin: 0;
  @media ${mobileSize} {
    font-size: 18px;
    text-align: center;
  }
`;

const Section = styled.section<{ reverse: boolean }>`
  display: flex;
  flex-direction: ${props => (props.reverse ? "row-reverse" : "row")};
  align-items: center;
  gap: 56px;
  margin-bottom: 80px;

  @media ${mobileSize} {
    flex-direction: column;
    gap: 32px;
    margin-bottom: 56px;
  }
`;

const StyledImg = styled.img`
  width: 285px;
  height: 285px;

  @media ${mobileSize} {
    width: 236px;
    height: 236px;
  }
`;

export { AppPitchSegment };
