import axios from "axios";
import OneSignal from "react-onesignal";
import { GATSBY_BE_URL, ONESIGNAL_API_KEY } from "../util/variables";
// import { getMixpanelDistinctId } from "./mixpanel";

OneSignal.init({ appId: ONESIGNAL_API_KEY });

export const setOnesignalExternalID = async (id: string) => {
  try {
    OneSignal.setExternalUserId(id);
  } catch (err) {
    console.error(err);
  }
};

export const setOnesignalEmail = async (email: string) => {
  try {
    OneSignal.setEmail(email);
  } catch (err) {
    console.error(err);
  }
};

// const setSubscription = async () => {
//   try {
//     OneSignal.setSubscription(true);
//   } catch (err) {
//     console.error(err);
//   }
// }

// export const setOneSignalAttributes = async (id: string, email: string) => {
//   await setOnesignalExternalID(id);
//   await setOnesignalEmail(email);
//   // await setSubscription();
//   console.log("OneSignal Attributes set");
// };

export const setOneSignalAttributes = async (id: string, email: string) => {
  try {
    await axios.post(
      `${GATSBY_BE_URL}/createOnesignalUser`,
      JSON.stringify({ email, uid: id }),
    );
  } catch (err) {
    console.log(err);
    return { error: "issue setting Onesignal device" };
  }
};
