import * as React from "react";
import styled from "styled-components";
import { AppText, AppTitle, Wrapper } from "../../ui";
import theme from "../../../util/theme";
import { mobileSize, laptopSize, TERMS_OF_SERVICE_PATH, PRIVACY_POLICY_PATH } from "../../../util/variables";
import { graphql, Link, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { AppLink } from "../../ui/AppLink";
import { OfferSegmentI } from "../../../hooks/useOffer";
import { calculateDiscountedPrice } from "../../../util/numbers";
import { offer_landing_page_click } from "../../../services/mixpanel/discount-flow-events";

const OfferSegment: React.FC<OfferSegmentI> = ({ priceData }) => {
  const { trial, percentOff, annual_price, currency_symbol, price_id, coupon_id } = priceData;
  const data = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "homepage/deep-understanding-desktop.webp" }) {
        childImageSharp {
          fixed(quality: 100, height: 623, width: 1040) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      mobile: file(relativePath: { eq: "homepage/deep-understanding-desktop.webp" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  const discountedPrice = calculateDiscountedPrice(Number(annual_price), percentOff ?? 0);

  const getOfferText = () => {
    if (trial && percentOff) {
      return `Get a 7-day free trial and ${percentOff}% on our in-app price`;
    }

    if (trial) {
      return "Get a 7-day free trial for our app.";
    }

    return `Get ${percentOff}% on our in-app price`;
  };

  return (
    <ContainerDiv id="more">
      <Wrapper>
        <ContentDiv>
          <AlignVerticallyDiv>
            <MobileImageDiv>
              <Img
                fluid={data.mobile.childImageSharp.fluid}
                alt="Imprint - Deep Understanding"
                loading="eager"
              />
            </MobileImageDiv>
            <WhiteAppTitle component="h2">Imprint Premium Offer.</WhiteAppTitle>
            <WhiteAppText fontSize={52} mobileFontSize={36} style={{ marginBottom: 12 }}>
              {percentOff ? (
                <s style={{ marginRight: 8 }}>
                  {currency_symbol}
                  {annual_price}
                </s>
              ) : null}
              <span style={{ fontWeight: 500 }}>
                {currency_symbol}
                {discountedPrice}
              </span>
            </WhiteAppText>
            <WhiteAppText>{getOfferText()}</WhiteAppText>
            <StyledLink
              dark
              to="/subscription/register?from=email"
              state={{
                couponId: coupon_id,
                priceId: price_id,
                trial,
              }}
              onClick={() => offer_landing_page_click("offer")}
            >
              Get the Offer
            </StyledLink>
            <DetailsText>
              By continuing, you agree to Imprint’s{" "}
              <Link to={TERMS_OF_SERVICE_PATH}>
                Terms of Use
              </Link>{" "}
              and{" "}
              <Link to={PRIVACY_POLICY_PATH}>
                Privacy Policy
              </Link>
              . This offer is valid for a limited time only for Imprint premium access.{" "}
              {trial
                ? "Enjoy a complimentary 7-day trial, and after that, subscription will renew at"
                : "Starts at"}{" "}
              {percentOff
                ? `${currency_symbol}${discountedPrice} for first year and then annually at ${currency_symbol}${annual_price} after that`
                : `${currency_symbol}${annual_price} each year`}
              . Cancel anytime in your account settings at least 24 hours in advance. This offer is
              only available for subscription purchases made via our website.
            </DetailsText>
          </AlignVerticallyDiv>
          <ImgDiv>
            <Img
              fixed={data.desktop.childImageSharp.fixed}
              style={{
                display: "inherit",
                minHeight: "100%",
                maxHeight: "100%",
                top: 0,
                bottom: 0,
              }}
              imgStyle={{
                objectFit: "stretch",
                objectPosition: "right",
              }}
              alt="Imprint - Deep Understanding"
              loading="eager"
            />
          </ImgDiv>
        </ContentDiv>
      </Wrapper>
    </ContainerDiv>
  );
};

const MobileImageDiv = styled.div`
  display: none;
  @media ${mobileSize} {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100vw;
    max-height: 100%;
    /* min-height: auto; */
    margin-left: -30px;
    margin-bottom: 30px;
  }
`;

const AlignVerticallyDiv = styled.div`
  max-height: 623px;
  min-height: 623px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 5;
  @media ${mobileSize} {
    min-height: fit-content;
    max-height: fit-content;
    width: 100%;
  }
`;

const ImgDiv = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  min-height: 100%;
  @media ${laptopSize} {
    top: 0;
    bottom: 0;
    right: -20%;
  }
  @media ${mobileSize} {
    display: none;
  }
`;

const WhiteAppTitle = styled(AppTitle as any)`
  color: ${theme.WHITE_COLOR};
  font-size: 36px;
  @media ${laptopSize} {
    z-index: 4;
    max-width: 50vw;
  }
  @media ${mobileSize} {
    z-index: 1;
    max-width: 100%;
  }
`;

const WhiteAppText = styled(AppText as any)`
  color: ${theme.WHITE_COLOR};
  @media ${laptopSize} {
    z-index: 4;
    max-width: 50vw;
  }
  @media ${mobileSize} {
    z-index: 1;
    max-width: 100%;
  }
`;

const DetailsText = styled(AppText as any)`
  color: ${theme.WHITE_COLOR};
  max-width: 700px;
  @media ${laptopSize} {
    z-index: 1;
    max-width: 50vw;
  }
  @media ${mobileSize} {
    max-width: none;
    text-align: center;
    font-size: 14px;
    line-height: 17px;
  }

  a {
    color: ${theme.WHITE_COLOR};
    text-decoration: underline;
  }
`;

const StyledLink = styled(AppLink as any)`
  margin-bottom: 32px;
  @media ${mobileSize} {
    margin-bottom: 24px;
  }
`;

const ContainerDiv = styled.div`
  background: ${theme.PRIMARY_COLOR};
  width: 100%;
  position: relative;
  min-height: 623px;
  max-height: 623px;
  /* z-index: -2; */
  @media ${mobileSize} {
    min-height: fit-content;
    max-height: fit-content;
    padding-bottom: 60px;
  }
`;

const ContentDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export { OfferSegment };
