import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { BasicLayout } from "../../../layouts/BasicLayout";
import styled from "styled-components";
import { BottomNavSegment } from "../../../components/PageSegments";
import theme from "../../../util/theme";
import { TestimonialsSection } from "../../../components/PageSegments/offer/variant/TestimonialsSection";
import { StatsSection } from "../../../components/PageSegments/offer/variant/StatsSection";
import { HeroSection } from "../../../components/PageSegments/offer/variant/HeroSection";
import { LibrarySection } from "../../../components/PageSegments/offer/variant/LibrarySection";
import { TopBanner } from "../../../components/ui/TopBanner";
import { baseUrl, mobileSize } from "../../../util/variables";
import { useOffer } from "../../../hooks/useOffer";
import { NavBar } from "../../../components/ui";
import { getStripeUrlV2 } from "../../../actions/stripeAction";
import { offer_landing_page_click } from "../../../services/mixpanel/discount-flow-events";
import { useAuth } from "../../../hooks/useAuth";

// used for email capture. New and returning users
export default (data: any) => {
  const { showExpiredBanner, priceData } = useOffer(data.location, "B");
  const { user, loading: loadingAuth } = useAuth();
  const [stripeUrl, setStripeUrl] = useState<string | null>(null);
  const [requestState, setRequestState] = useState<"idle" | "loading" | "success" | "error">("idle");
  const [buttonClicked, setButtonClicked] = useState(false);
  const { search } = data.location;

  useEffect(() => {
    if (loadingAuth || !user) return;
    const getStripeUrl = async () => {
      try {
        setRequestState("loading");
        console.debug("creating stripe url");
        const url = await getStripeUrlV2({
          discounts: [
            { coupon: priceData.coupon_id },
          ],
          priceId: priceData.price_id,
          email: user.email ?? "",
          success_url: `${baseUrl}/subscription/create-account/complete?session_id={CHECKOUT_SESSION_ID}&subscription_type=coupon&uid=${user.uid}`,
          cancel_url: `${baseUrl}/offer/go${search}`,
          currency: priceData.currency_code,
          mode: "subscription",
          metadata: {
            app_user_id: user.uid,
          },
        });
        console.debug("stripe url:", url);
        setStripeUrl(url);
        setRequestState("success");
      } catch (e) {
        console.error("stripe url creation failed:", e);
        setRequestState("error");
      }
    };

    if (requestState === "idle") {
      getStripeUrl();
    }
  }, [loadingAuth, user, priceData, requestState]);

  useEffect(() => {
    if (!buttonClicked) return;

    if (user) {
      switch (requestState) {
        case "loading":
          return;
        case "error":
          navigate("/subscription/register?from=email", {
            state: {
              couponId: priceData.coupon_id,
              priceId: priceData.price_id,
              trial: priceData.trial,
            },
          });
          break;
        case "success":
          if (stripeUrl) document.location.href = stripeUrl;
          break;
        default:
          console.log("this should not happen");
      }
    } else {
      navigate("/subscription/register?from=email", {
        state: {
          couponId: priceData.coupon_id,
          priceId: priceData.price_id,
          trial: priceData.trial,
        },
      });
    }
  }, [stripeUrl, buttonClicked, priceData, user, requestState]);

  const handleClick = (section: string) => {
    offer_landing_page_click(section);
    setButtonClicked(true);
  };

  return (
    <BasicLayout>
      <Main>
        {showExpiredBanner && (
          <TopBanner show fixed={false}>
            <BannerText>
              {`The offer you selected has expired, but you can still get ${priceData.percentOff}% off.`}
            </BannerText>
          </TopBanner>
        )}
        <NavBar initiallyWhite />
        <HeroSection priceData={priceData} buttonClicked={buttonClicked} handleClick={handleClick} />
        <TestimonialsSection priceData={priceData} buttonClicked={buttonClicked} handleClick={handleClick} />
        <StatsSection priceData={priceData} buttonClicked={buttonClicked} handleClick={handleClick} />
        <LibrarySection priceData={priceData} buttonClicked={buttonClicked} handleClick={handleClick} />
        <BottomNavSegment />
      </Main>
    </BasicLayout>
  );
};

const Main = styled.main`
  background: ${theme.WHITE_COLOR};
  h1 {
    font-family: ${theme.PRIMARY_FONT};
    font-size: 56px;
    font-weight: 400;
    line-height: 70px;
    text-align: center;
    color: ${theme.BLACK_COLOR};
  }
  @media ${mobileSize} {
    h1 {
      font-size: 36px;
      line-height: 45px;
    }
  }
`;

const BannerText = styled.p`
  margin: 0;
  line-height: 1.25;
  @media ${mobileSize} {
    font-size: 20px;
    /* margin-top: 60px; */
  }
`;
