import axios from "axios";
import { IP_GEOLOCATION_API_KEY } from "../util/variables";
export interface GeolocationI {
  country: string;
  country_code2: string;
  ip: string;
  currency: {
    code: string;
    name: string;
    symbol: string;
  };
  is_eu: boolean;
}

export const getGeolocation = async (): Promise<GeolocationI> => {
  // https://ipgeolocation.io/documentation/ip-geolocation-api.html
  // to test different locations, add the ip parameter (ex: ip=8.8.8.8)
  // you can use a site like this to find an ip address to test https://lite.ip2location.com/ip-address-ranges-by-country

  // TEST IPS
  // New Zealand 101.100.0.0
  // Canada      100.42.20.0
  // UK          101.167.212.0
  // EU          100.42.176.0

  const response = await axios.get(
    `https://api.ipgeolocation.io/ipgeo?apiKey=${IP_GEOLOCATION_API_KEY}&fields=country_name,country_code2,currency,is_eu`,
  );
  return response.data;
};
