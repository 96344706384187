import React from "react";
import { FlexDiv } from "../../../ui";
import theme from "../../../../util/theme";
import styled from "styled-components";
import { mobileSize } from "../../../../util/variables";
import StarsSrc from "../../../../content/assets/StarsBlack.svg";
import { AppLoadingButton, Wrapper } from "../../../ui";
import { OfferGoSegmentI } from "../../../../hooks/useOffer";
import AuthStatusMessage from "./AuthStatusMessage";

interface ReviewProps {
  children: React.ReactNode;
}

const Review: React.FC<ReviewProps> = ({ children }) => {
  return (
    <ReviewContainer>
      <FlexDiv direction="column" align="center">
        {children}
        <Stars src={StarsSrc} alt="5 stars" />
      </FlexDiv>
    </ReviewContainer>
  );
};

export const TestimonialsSection: React.FC<OfferGoSegmentI> = ({ priceData, buttonClicked, handleClick }) => {
  const { percentOff } = priceData;
  return (
    <Section>
      <Wrapper>
        <FlexDiv direction="column" align="center" gap={24}>
          <h1 style={{ padding: "0 10px" }}>What Imprint members say...</h1>
          <ReviewsContainer>
            <Review>
              <p>
                “<b>Nothing else is like Imprint</b>— you have to try it to see
                its <b>magic.</b>”
              </p>
            </Review>
            <Review>
              <p>
                “Imprint is <b>completely life changing.</b> As a person that
                always loves reading but doesn&apos;t have much time,
                this app is <b>perfect for me.</b>”
              </p>
            </Review>
            <Review>
              <p>
                “This app is one of the <b>best things</b> you can do for yourself.”
              </p>
            </Review>
          </ReviewsContainer>
          <FlexDiv direction="column" align="center">
            <AppLoadingButton
              type="button"
              loading={buttonClicked}
              disabled={false}
              style={{ fontSize: 18 }}
              onClick={() => handleClick("testimony")}
            >
              {`Get ${percentOff}% Off`}
            </AppLoadingButton>
            <AuthStatusMessage priceData={priceData} />
          </FlexDiv>
        </FlexDiv>
      </Wrapper>
    </Section >
  );
};

const Section = styled.section`
  margin: 38px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ReviewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  max-width: 700px;
  background-color: #FAFAFA;
`;

const ReviewContainer = styled.div`
  padding: 16px 20px;
  border: 2px solid #EDEEEF;
  text-align: center;
  border-radius: 16px;
  box-shadow: 0px 2px 8px 0px #A4A4A440;
  font-size: 20px;
  font-family: ${theme.SECONDARY_FONT};
  color: ${theme.BLACK_COLOR};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 144px;
  width: 100%;
  @media ${mobileSize} {
    font-size: 18px;
  }
`;

const Stars = styled.img`
  @media ${mobileSize} {
    width: 129px;
    height: 24px;
  }
  fill: ${theme.BLACK_COLOR};
  pointer-events: none;
`;
