import React, { useEffect } from "react";
import styled from "styled-components";
import theme from "../../../../util/theme";
import DesktopLibrary from "../../../../content/img/prepayment/library.png";
import MobileLibrary from "../../../../content/img/prepayment/library-mobile.png";
import { mobileSize } from "../../../../util/variables";
import { AppText, FlexDiv } from "../../../ui";
import { useInView } from "../../../../hooks/useInView";
import { web_acq_prepayment_section_view } from "../../../../services/mixpanel/acquisition-events";

interface LibraryItem {
  category: string;
  books: string[];
}

interface LibrarySegmentProps {
  libraryData?: LibraryItem[];
}

const LIBRARY_DATA: LibraryItem[] = [
  {
    category: "Psychology",
    books: ["Interpersonal Dynamics", "Thinking, Fast and Slow", "Outliers"],
  },
  {
    category: "Productivity",
    books: ["Andrew Huberman's Tips for Peak Productivity", "Essentialism", "Getting Things Done"],
  },
  {
    category: "Philosophy",
    books: ["Essential Philosophy: Theories & Thinkers", "The Happiness Hypothesis", "The Obstacle is the Way"],
  },
  {
    category: "Personal Development",
    books: ["Atomic Habits", "Think Like a Monk", "Rich Dad, Poor Dad"],
  },
  {
    category: "Health & Wellness",
    books: ["Why We Sleep", "In Defense of Food", "Ikigai"],
  },
  {
    category: "History",
    books: ["Sapiens", "Guns, Germs and Steel", "The Sixth Extinction"],
  },
  {
    category: "Business",
    books: [
      "The Lean Startup",
      "How to Win Friends and Influence People",
      "Never Split the Difference",
    ],
  },
  {
    category: "Leadership",
    books: ["Dare to Lead", "Start With Why", "48 Laws of Power"],
  },
  {
    category: "Science & Technology",
    books: ["The Uninhabitable Earth", "The Selfish Gene", "Astrophysics for People in a Hurry"],
  },
];

export const LibrarySegment: React.FC<LibrarySegmentProps> = ({
  libraryData = LIBRARY_DATA,
}) => {
  const { inView, ref, disconnect } = useInView({
    threshold: 0.25,
  });

  useEffect(() => {
    if (inView) {
      web_acq_prepayment_section_view("Library");
      disconnect();
    }

    return () => {
      disconnect();
    };
  }, [inView, disconnect]);

  return (
    <Container ref={ref}>
      <Content>
        <Header>A SNEAK PEEK OF IMPRINT’S EXTENSIVE LIBRARY</Header>
        <ImgContainer>
          <picture>
            <source media="(min-width: 768px)" srcSet={DesktopLibrary} type="image/png" />
            <source media="(max-width: 768px)" srcSet={MobileLibrary} type="image/png" />
            <img src={DesktopLibrary} alt="Imprint book collection" />
          </picture>
        </ImgContainer>
        <Grid>
          {libraryData.map(item => (
            <FlexDiv key={item.category} direction="column" justify="center" gap={8}>
              <LibraryCategoryH3>{item.category}</LibraryCategoryH3>
              {item.books.map(book => (
                <AppText key={book} noMargin textAlign="center" fontSize={18}>
                  {book}
                </AppText>
              ))}
              <AppText
                color={theme.PRIMARY_COLOR}
                fontSize={18}
                textAlign="center"
                fontWeight={500}
              >
                ...and more
              </AppText>
            </FlexDiv>
          ))}
        </Grid>
      </Content>
    </Container>
  );
};

const Container = styled.section`
  padding: 80px;
  padding-bottom: 0px;
  width: 100%;
  background-color: ${theme.PRIMARY_COLOR};
  @media ${mobileSize} {
    padding: 44px 24px 0 24px;
  }
`;

const Content = styled.div`
  max-width: 1200px;
  /* padding: 56px 88px; */
  margin: 0 auto;
  border-radius: 16px;
  background-color: ${theme.WHITE_COLOR};
  @media ${mobileSize} {
    /* padding: 44px 24px 0 24px; */
  }
`;

const Header = styled.h2`
  padding: 30px;
  margin: 0 auto;
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
  background: ${theme.BLACK_COLOR};
  color: ${theme.WHITE_COLOR};
  text-transform: uppercase;
  font-family: ${theme.SECONDARY_FONT};
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  @media ${mobileSize} {
    font-size: 18px;
  }
`;

const ImgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 48px;
  background-color: #edeeef;
  @media ${mobileSize} {
    padding: 10px 0;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  padding: 0 48px 48px 48px;
  @media ${mobileSize} {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    padding: 0px 36px 36px 36px;
  }
`;

const LibraryCategoryH3 = styled.h3`
  font-family: ${theme.PRIMARY_FONT};
  color: ${theme.BLACK_COLOR};
  font-weight: 400;
  font-size: 24px;
  text-align: center;
`;
