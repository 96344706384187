import React from "react";
import styled from "styled-components";
import theme from "../../../../util/theme";
import { mobileSize } from "../../../../util/variables";
import { FlexDiv } from "../../../ui";

const SubscriptionTimeline = () => {
  return (
    <TimelineWrapper>
      <Line />
      <BodyContext>
        <Circle green />
        <FlexDiv direction="column">
          <BodyMain>NOW</BodyMain>
          <BodyDesc>
            <b>Unlock</b> the full Imprint library and start learning <b>today</b>.
          </BodyDesc>
        </FlexDiv>
      </BodyContext>
      <BodyContext>
        <Circle />
        <FlexDiv direction="column">
          <BodyMain>IN 5 DAYS</BodyMain>
          <BodyDesc>
            <b>Receive a reminder</b> that your free trial is ending.
          </BodyDesc>
        </FlexDiv>
      </BodyContext>

      <BodyContext>
        <Circle />
        <FlexDiv direction="column">
          <BodyMain>IN 7 DAYS</BodyMain>
          <BodyDesc>
            You&apos;ll be <b>charged</b> for Imprint. Cancel anytime.
          </BodyDesc>
        </FlexDiv>
      </BodyContext>
    </TimelineWrapper>
  );
};

export default SubscriptionTimeline;

const TimelineWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 58px;

  @media ${mobileSize} {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;
  }
`;

const Circle = styled.div<{ green?: boolean }>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 auto 40px auto;
  background-color: ${props => (props.green ? theme.PRIMARY_COLOR : "#D2D5D8")};
  @media ${mobileSize} {
    position: relative;
    top: 2px;
    height: 20px;
    width: 20px;
    aspect-ratio: 1/1;
  }
`;

const BodyContext = styled.div`
  max-width: 237px;
  position: relative;
  /* margin: 0px; */
  /* margin-left: 16px; */
  text-align: center;

  @media ${mobileSize} {
    display: flex;
    align-items: flex-start;
    gap: 18px;
    text-align: left;
    max-width: none;
  }
`;

const Line = styled.div`
  position: absolute;
  top: 21px;
  left: 50%;
  transform: translateX(-50%);
  /* border-top: 1px dotted #d2d5d8;
  border-spacing: 1px; */
  height: 1px;
  width: calc(100% - 184px); // 100% - dist_circle_to_edge * 2

  /*Horizontal*/
  background-image: linear-gradient(to right, #d2d5d8 33%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 4px 1px;
  background-repeat: repeat-x;

  /*Vertical*/
  @media ${mobileSize} {
    top: 0px;
    left: 10px;
    height: 75%;
    width: 1px;

    background-image: linear-gradient(#d2d5d8 33%, rgba(255, 255, 255, 0) 0%);
    background-position: right;
    background-size: 1px 4px;
    background-repeat: repeat-y;
  }
`;

const BodyDesc = styled.p`
  margin: 4px 0px;

  font-family: ${theme.SECONDARY_FONT};
  font-size: 18px;
  line-height: 20px;
  color: #292032;
  @media ${mobileSize} {
    font-size: 16px;
  }
`;

const BodyMain = styled.p`
  font-family: ${theme.SECONDARY_FONT};
  font-size: 24px;
  line-height: 28px;
  color: ${theme.PRIMARY_COLOR};
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
  @media ${mobileSize} {
    font-size: 18px;
    line-height: 24px;
  }
`;
