import React, { useEffect } from "react";
import styled from "styled-components";
import { AppText, AppTitle } from "../../../ui";
import theme from "../../../../util/theme";
import { mobileSize } from "../../../../util/variables";
import StarsSrc from "../../../../content/assets/Stars.svg";
import { AnchorLinkButton } from "../../../ui/AnchorLinkButton";
import { useInView } from "../../../../hooks/useInView";
import { web_acq_prepayment_section_view } from "../../../../services/mixpanel/acquisition-events";

export const RatingSegment = ({ scrollId }: { scrollId: string }) => {
  const { inView, ref, disconnect } = useInView({
    threshold: 1,
  });

  useEffect(() => {
    if (inView) {
      web_acq_prepayment_section_view("Rating");
      disconnect();
    }

    return () => {
      disconnect();
    };
  }, [inView, disconnect]);

  return (
    <Background ref={ref}>
      <Container>
        <AppTitle component="h2" margin="0" color={theme.WHITE_COLOR} textAlign="center">
          Over 25,000 5-star reviews on iOS App Store and Google Play
        </AppTitle>
        <Stars src={StarsSrc} alt="5 stars" width={232} height={43} />
        <AppText
          fontSize={24}
          color={theme.WHITE_COLOR}
          textAlign="center"
          style={{ maxWidth: 700 }}
        >
          “Imprint is <b>completely life changing</b>. As a person that always loves reading but
          doesn’t have much time, this app is <b>perfect for me</b>.”
        </AppText>
        <AnchorLinkButton
          href={"#" + scrollId}
          background={theme.BLACK_COLOR}
          style={{ border: "1px solid white" }}
        >
          Get Imprint
        </AnchorLinkButton>
      </Container>
    </Background>
  );
};

const Background = styled.section`
  background-color: ${theme.PRIMARY_COLOR};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px;
  @media ${mobileSize} {
    padding: 64px 24px;
  }
`;

const Stars = styled.img`
  filter: brightness(100);
  @media ${mobileSize} {
    width: 129px;
    height: 24px;
  }
`;
