import React, { useContext, useEffect, useState } from "react";
import { BasicLayout } from "../../layouts/BasicLayout";
import styled from "styled-components";
import { baseUrl, DEFAULT_CURRENCY_CODE, mobileSize } from "../../util/variables";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  getStripeUrlV2,
  retrievePromotionCodeFromCouponId,
  validatePromotionCode,
} from "../../actions/stripeAction";
import { AppLogo } from "../../components/ui/AppLogo";
import { LocalPriceContext } from "../../providers/LocalPriceProvider";
import { AppTitle } from "../../components/ui/AppTitle";
import { AppButton, FlexDiv, AppText } from "../../components/ui";
import theme from "../../util/theme";
import { navigate } from "gatsby";
import { gift_page_view, gift_redeem_get_started } from "../../services/mixpanel/gift-flow-events";
import { fbq } from "../../services/pixel";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { sendSlackErrorMessage } from "../../services/slack";
import { useAuth } from "../../hooks/useAuth";

interface FormValues {
  code: string;
}

const validationSchema = Yup.object().shape({
  code: Yup.string().required("Code is required"),
});
const Redeem = () => {
  const { location, currentPrice } = useContext(LocalPriceContext);
  const [loading, setLoading] = useState(false);
  const { user, loading: isAuthLoading } = useAuth();

  useEffect(() => {
    gift_page_view("GiftRedeem");
  }, []);

  useEffect(() => {
    if (isAuthLoading) return;

    if (!user?.email) {
      navigate("/gift/claim");
    }
  }, [isAuthLoading, user]);

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);
    gift_redeem_get_started();
    const validateCode = await validatePromotionCode(values.code);
    console.log("validateCode", validateCode);
    if (!validateCode.valid) {
      alert("Invalid code");
      setLoading(false);
      return;
    }

    const promotionCode = await retrievePromotionCodeFromCouponId(values.code);

    fbq("trackCustom", "InitiateCheckoutGiftRedemption");

    if (!currentPrice.price_id_gift) {
      alert("An Error has occurred. Please contact support info@imprintapp.com");
      sendSlackErrorMessage(`Gifting redemption failed: ${email} ${values.code} `);
      setLoading(false);
      return;
    }

    const url = await getStripeUrlV2({
      priceId: currentPrice.price_id_gift,
      email: user?.email ?? undefined,
      success_url: `${baseUrl}/gift/claim/complete`,
      cancel_url: `${baseUrl}/gift/redeem`,
      currency: location?.currency.code ?? DEFAULT_CURRENCY_CODE,
      mode: "subscription",
      discounts: [
        {
          promotion_code: promotionCode?.id,
        },
      ],
      subscription_data: {
        metadata: {
          order_type: "gift_coupon",
        },
      },
    });

    if (url) {
      window.location.href = url;
    }

    setLoading(false);
  };

  return (
    <BasicLayout>
      <Container>
        <LogoContainer>
          <AppLogo />
        </LogoContainer>
        <H1 style={{ color: "#10130C" }}>Enter Your Gift Code</H1>
        <Formik
          initialValues={{ code: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <StyledForm>
              <StyledText style={{ color: "#10130C" }}>
                Hint: Your unique code can be found in your email.
              </StyledText>
              <Grid>
                <FormGroup>
                  <StyledField name="code" placeholder="Gift Code" />
                  <ErrorContainer>
                    <ErrorMessage name="code" component="div" />
                  </ErrorContainer>
                </FormGroup>
              </Grid>
              <FlexDiv style={{ width: "100%" }} justify="center">
                <AppButton type="submit" disabled={isSubmitting || loading || isAuthLoading}>
                  {loading ? (
                    <FlexDiv justify="center" align="center">
                      <LoadingSpinner color={theme.WHITE_COLOR} />
                    </FlexDiv>
                  ) : (
                    "Continue"
                  )}
                </AppButton>
              </FlexDiv>
            </StyledForm>
          )}
        </Formik>
      </Container>
    </BasicLayout>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100svh;
  padding: 80px 64px;
  @media ${mobileSize} {
    padding: 0 24px;
    justify-content: flex-start;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  left: 100px;
  top: 80px;
  display: block;
  @media ${mobileSize} {
    position: static;
    margin: 64px 0 32px 0;
    height: 20px;
    width: 80px;
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 462px;
`;

const H1 = styled(AppTitle as any)`
  margin-bottom: 0;
  text-align: center;
  @media ${mobileSize} {
    font-size: 36px;
    margin-top: 32px;
  }
`;

const StyledText = styled(AppText as any)`
  font-size: 24px;
  font-weight: 400;
  margin: 16px 0 64px 0;
  font-family: ${theme.SECONDARY_FONT};
  text-align: center;
  @media ${mobileSize} {
    font-size: 20px;
    margin: 16px 0 40px 0;
  }
`;

const Grid = styled.div`
  width: 100%;
  max-width: 327px;
  margin-bottom: 140px;
  @media ${mobileSize} {
    margin-bottom: 200px;
  }
`;

const FormGroup = styled.div`
  input,
  textarea {
    width: 100%;
    border: 2px solid #d2d5d8;
    border-radius: 8px;
  }

  input {
    padding: 20px 16px;
  }

  textarea {
    padding: 12px 18px;
  }

  textarea {
    height: 100px;
    resize: vertical;
  }

  div {
    color: red;
    font-size: 0.8rem;
    margin-top: 0.2rem;
  }

  span {
    display: block;
    margin-right: 12px;
    text-align: right;
    font-size: 12px;
    line-height: 0.5;
  }
`;

const StyledField = styled(Field)`
  &:focus {
    outline: 2px solid #20614c;
  }
`;

const ErrorContainer = styled.div`
  height: 8px;
  margin: 0;
  margin-bottom: 2rem;
  margin-left: 2px;
  color: red;
  font-size: 10px;
`;

export default Redeem;
