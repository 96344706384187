import { useEffect, useState } from "react";
import { setMixpanelProperties } from "../services/mixpanel/mixpanel";

interface ABTestConfig<T> {
  testName: string;
  variants: {
    [key: string]: T; // map variant keys to values of the AB (Ex: different text, colors, etc)
  };
  weights?: {
    [key: string]: number;
  };
}

export function useABTest<T>({ testName, variants, weights }: ABTestConfig<T>) {
  const [variant, setVariant] = useState<T>();

  useEffect(() => {
    // Get stored variant or assign new one
    const storedVariant = localStorage.getItem(testName);
    if (storedVariant && variants[storedVariant]) {
      setVariant(variants[storedVariant]);
    } else {
      const variantKeys = Object.keys(variants);
      const selectedKey = selectVariant(variantKeys, weights);

      localStorage.setItem(testName, selectedKey);
      setVariant(variants[selectedKey]);

      setMixpanelProperties({ [testName]: selectedKey }, true);
    }
  }, [testName, variants, weights]);

  return variant;
}

function selectVariant(variants: string[], weights?: { [key: string]: number }): string {
  if (!weights) {
    return variants[Math.floor(Math.random() * variants.length)];
  }

  const totalWeight = Object.values(weights).reduce((sum, weight) => sum + weight, 0);
  let random = Math.random() * totalWeight;

  for (const variant of variants) {
    random -= weights[variant] || 0;
    if (random <= 0) return variant;
  }

  return variants[0];
}
